import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimeSheets } from "../../../../store/selectors";
import { setTimeSheet, setTimeSheets } from "../../../../store/actions/timesheet.action";
import { timeStyle } from "./timeCalculator";
import { TableContainer } from "../../../../components/Dashboard/CommonStyled";
import { Box } from "@material-ui/core";
import { Name } from "../../Team/TeamTable";
import { Table } from "antd";
import { IFilterInterface } from "./index";
import TableActionMenu from "../../../../components/Dashboard/TableActionMenu";
import moment from "moment-timezone";
import * as TimeSheetApi from "../../../../apis/timesheet.api";

interface IProps {
  setOpenModal: (value: boolean) => void;
  filterParams: IFilterInterface;
  setWorkTime: (value: number) => void;
  secondReading: number;
  setProgress: (value: boolean) => void;
  setSecondReading: (value: number) => void;
}

const TimeSheetTable = ({
  setOpenModal,
  filterParams,
  setWorkTime,
  secondReading,
  setProgress,
  setSecondReading
}: IProps) => {
  const dispatch = useDispatch();
  const timeSheets = useSelector(getTimeSheets);

  const columns = [
    {
      title: 'Developer',
      dataIndex: 'developer',
      render: (developer) => <Name>
        <div className='avatar'>
          {developer.name[0]}
        </div>
        {developer.name}
      </Name>
    },
    {
      title: 'Project',
      dataIndex: 'project',
      render: (project) => <Name>
        <div className='avatar'>
          {project.name[0]}
        </div>
        {project.name}
      </Name>
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (startDate) => (
        <div>{moment(new Date(startDate)).format('DD/MM/YYYY HH:mm:ss')}</div>
      )
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (endDate) => (
        <div>{moment(new Date(endDate)).format('DD/MM/YYYY HH:mm:ss')}</div>
      )
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (duration: number) => (
        <div>
          {timeStyle(duration).hour}:{timeStyle(duration).minute}:{timeStyle(duration).second}
        </div>
      )
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row) => <TableActionMenu row={row} handleDeleteRow={handleDeleteRow} handleEditRow={handleEditRow} />
    }
  ];

  const tableData = useMemo(() => {
    return timeSheets.map((item) => {
      if (item.progressing) {
        return {
          ...item,
          key: item.id,
          endDate: moment(item.endDate).add(secondReading, 'second'),
          duration: item.duration + secondReading,
        }
      } else {
        return {
          ...item,
          key: item.id,
        }
      }
    })
  }, [timeSheets, secondReading]);

  const handleDeleteRow = async (id: string) => {
    setProgress(false);
    setSecondReading(0);
    await TimeSheetApi.deleteById(id);
    TimeSheetApi.getTimeSheets(filterParams).then((res) => {
     dispatch(setTimeSheets(res.data));
     setWorkTime(res.time);
    });
  };

  const handleEditRow = async (id: string) => {
    await TimeSheetApi.getById(id).then((res) => dispatch(setTimeSheet(res)));
    setOpenModal(true);
  };

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
      <Box mt={2}>
        <p>{ timeSheets.length } { timeSheets.length > 1 ? 'time sheets' : 'time sheet' }</p>
      </Box>
    </TableContainer>
  )
}
export default TimeSheetTable;
