export const SET_SCROLL_POS = 'SET_SCROLL_POS'

// dashboard
export const SET_WIDGETS = 'SET_WIDGETS'
export const SET_SHOW_TIME_TRACKER_WIDGET = 'SET_SHOW_TIME_TRACKER_WIDGET'

// project

export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_PROJECT = 'SET_PROJECT'

// team

export const SET_TEAMS = 'SET_TEAMS'
export const SET_TEAM = 'SET_TEAM'

// member

export const SET_MEMBERS = 'SET_MEMBERS'

// task
export const SET_TASKS = 'SET_TASKS'
export const SET_TASK = 'SET_TASK'

// invoice
export const SET_INVOICE_DRAFT = 'SET_INVOICE_DRAFT'
export const SET_INVOICES = 'SET_INVOICES'
export const SET_INVOICE = 'SET_INVOICE'

// client
export const SET_CLIENTS = 'SET_CLIENTS'

// timesheet

export const SET_TIMESHEETS = 'SET_TIMESHEETS'
export const SET_TIMESHEET = 'SET_TIMESHEET'
