import Http from "./http";
import { ITeamInterface } from "../components/Dashboard/Team/AddTeamModal";

export const create = (value: ITeamInterface) => {
  return Http.post('/team', value);
}

export const getAll = (searchValue: string) => {
  return Http.get('/team/all', { searchValue });
}

export const getById = (id: string) => {
  return Http.get(`/team/${id}`);
}

export const update = ({id, data}: {id: string, data: ITeamInterface}) => {
  return Http.patch(`/team/${id}`, data);
};

export const deleteById = (id: string) => {
  return Http.delete(`/team/${id}`);
}
