import React from "react";
import { Input as AInput, Button as AButton, InputProps } from "antd";
import styled, { css } from "styled-components";

const StyledInput = styled(AInput)`
  &.ant-input {
    border: 1px solid #d6dbe6;
    max-width: 480px;
    height: 40px;
    padding: 10px 16px 10px 16px;
    border-radius: 6px 0 0 6px;

    &:focus {
      border-color: #40a9ff !important;
      box-shadow: rgba(0, 123, 255, 0.25) 0 0 0 2px;
    }
  }
`;

const StyledButton = styled(AButton)<{ isCopied?: boolean }>`
  width: 64px;
  color: #2aa7ff !important;
  font-weight: 400 !important;
  border-color: #d6dbe6 !important;
  margin-left: -1px;
  border-radius: 0 6px 6px 0 !important;
  min-width: 40px !important;
  height: 40px !important;
  min-height: 40px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #fff;
  text-transform: uppercase;

  &:focus {
    border-color: #40a9ff !important;
    box-shadow: rgba(0, 123, 255, 0.25) 0 0 0 2px;
  }

  &:after {
    content: "Copied!";
    color: white;
    position: absolute;
    background: #000e;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 120%);
    border-radius: 4px;
    padding: 4px;
    font-size: 10px;
    text-transform: none;
    display: block;
    ${(props) =>
      !props.isCopied &&
      css`
        display: none;
      `}
  }
`;

interface IProps extends Omit<InputProps, "value" | "onCopy"> {
  onCopy: (inputValue: string) => void;
  value: string;
  isCopied?: boolean;
}

const CopyInput = (props: IProps) => {
  const { onCopy, isCopied, value, ...rest } = props;
  return (
    <AInput.Group style={{ width: "100%", display: "flex" }}>
      <StyledInput value={value} {...rest} />
      <StyledButton isCopied={isCopied} onClick={() => onCopy(props.value)}>
        Copy
      </StyledButton>
    </AInput.Group>
  );
};

export default CopyInput;
