import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { Box } from '@material-ui/core'
import { Search } from "@material-ui/icons";
import { Tooltip } from "antd";
import { Container } from "./style";
import { setTeams } from "../../../store/actions/team.action";
import TeamTable from './TeamTable'
import Input from "../../../components/Dashboard/Input";
import Button from "../../../components/Dashboard/Button";
import AddTeamModal from "../../../components/Dashboard/Team/AddTeamModal";
import * as TeamApi from "../../../apis/team.api";

const Team = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    TeamApi.getAll(searchValue).then((res) => dispatch(setTeams(res)));
  }, [searchValue]);

  return (
    <Container>
      <Box mb={2}>
        <h1>Teams</h1>
      </Box>
      <Box mt={4}>
        <Box dir="row" pt={3} pb={5} pl={1} className="search-wrapper">
          <Input
            className="search"
            prefix={<Search />}
            placeholder="Search teams"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Box className="actions-row">
            <Tooltip
              className="tooltip"
              title="Add new team to the organization"
              placement="left"
            >
              <Button
                className="primary-contained"
                onClick={() => setOpenModal(true)}
              >
                Add team
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box pt={3}>
          <TeamTable
            searchValue={searchValue}
            setOpenModal={setOpenModal}
          />
        </Box>
      </Box>
      <AddTeamModal
        onClose={() => setOpenModal(false)}
        open={openModal}
        searchValue={searchValue}
      />
    </Container>
  )
}

export default Team;
