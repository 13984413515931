import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router";

import * as S from "./styles";
import { FacebookIcon, LinkedinIcon, TwitterIcon, YoutubeIcon } from "../../Common/Images";
import { Button } from "../../Common/Button";

const menu = [
  {
    title: "Software",
    subMenu: [
      "TimeGo overview",
      "Time tracking",
      "Field service management",
      "Mac",
      "Windows",
      "Chrome",
      "Linux",
      "iPhone",
      "Android"
    ]
  },
  {
    title: "Integrations",
    subMenu: [
      "TimeGo + Tasks",
      "Asana",
      "Jira",
      "Trello",
      "Teamwork Projects",
      "Quickbooks",
      "Github",
      "Clickup",
      "Zapier",
      "See all"
    ]
  },
  {
    title: "More",
    subMenu: ["About us", "Download", "Blog", "Affiliates", "Resources", "Status"]
  },
  {
    title: "Contact",
    subMenu: ["Demo", "Help Center", "FAQ", "Contact Us", "Careers", "Press"]
  }
];

const Footer = () => {
  const history = useHistory();

  const gotoSignUpPage = () => {
    history.push("/auth/sign-up");
  };

  return (
    <S.Container>
      <div className="footer-top">
        <div className="container">
          <div className="left-menu">
            {menu.map((item) => (
              <div className="menu-group" key={item.title}>
                <h4>{item.title}</h4>
                {item.subMenu.map((label) => (
                  <S.MenuLink to="/" key={label}>
                    {label}
                  </S.MenuLink>
                ))}
              </div>
            ))}
          </div>
          <div className="right">
            <Box borderBottom="1px solid white" pb="45px">
              <h2>Ready to get started?</h2>
              <Button style={{ width: 415 }} onClick={gotoSignUpPage}>
                Sign me up
              </Button>
            </Box>
            <Box pt="35px">
              <h3>Follow us</h3>
              <div className="social-links">
                <Link to="/">
                  <img src={FacebookIcon} alt="facebook" />
                </Link>
                <Link to="/">
                  <img src={LinkedinIcon} alt="linkedin" />
                </Link>
                <Link to="/">
                  <img src={TwitterIcon} alt="twitter" />
                </Link>
                <Link to="/">
                  <img src={YoutubeIcon} alt="youtube" />
                </Link>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <Box display="flex" ml="40px">
            <span className="copyright">Copyright @ 2023 TimeGo, LLC</span>
          </Box>
          <Box display="flex" pl="5px" borderLeft="1px solid white">
            <Box ml="15px">
              <S.MenuLink to="/">Terms</S.MenuLink>
            </Box>
            <Box ml="15px">
              <S.MenuLink to="/">Privacy</S.MenuLink>
            </Box>
            <Box ml="15px">
              <S.MenuLink to="/">GDPR compliance</S.MenuLink>
            </Box>
          </Box>
        </div>
      </div>
    </S.Container>
  );
};

export default Footer;
