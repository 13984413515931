import React from "react";

import * as S from "./styles";
import { Form, Formik } from "formik";
import Input from "../../../components/Dashboard/Input";
import * as Yup from "yup";
import { DefaultButton, OptionalButton, PrimaryButton } from "../../../components/Common/Button";
import Select, { Option } from "../../../components/Dashboard/Select";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Space } from "antd";
import WizardLayout from "../../../components/Organization/WizardLayout/WizardLayout";
import { useHistory } from "react-router";
import * as OrganizationApi from '../../../apis/organization.api';

const urlRegExp = /(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.(com|org|net)/;
const companySizes = [
  { label: "Just me", value: 'PERSONAL' },
  { label: "2 - 10", value: 'MICRO' },
  { label: "11 - 50", value: 'SMALL' },
  { label: "50 - 250", value: 'MEDIUM' },
  { label: "251 - 500", value: 'LARGE' },
  { label: "500 +", value: 'ENTERPRISE' }
];

const industries = [
  { label: "Accommodation/Hospitality", value: "accommodation/hospitality" },
  { label: "Accounting", value: "accounting" },
  { label: "Agency", value: "agency" },
  { label: "Agriculture", value: "agriculture" },
  { label: "Architect & engineering", value: "architect-engineering" },
  { label: "Blockchain/Crypto", value: "Blockchain/crypto" },
  { label: "Cleaning", value: "cleaning" },
  { label: "Construction", value: "construction" },
  { label: "consulting", value: "consulting" },
  { label: "courier/deliver", value: "courier/deliver" },
  { label: "Digital Marketing", value: "digital-marketing" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Freelancing", value: "freelancing" },
  { label: "General contracting", value: "general-contracting" },
  { label: "Healthcare", value: "healthcare" },
  { label: "IT service", value: "it-service" },
  { label: "Landscaping", value: "landscaping" },
  { label: "legal", value: "legal" },
  { label: "logistics", value: "logistics" },
  { label: "Manufacturing", value: "manufacturing" },
  { label: "Marketing & advertising", value: "marketing-advertising" },
  { label: "media", value: "media" },
  { label: "Professional services", value: "professional-services" },
  { label: "Property management", value: "property-management" },
  { label: "Public relations & communications", value: "public-relations-communications" },
  { label: "Real estate", value: "real-estate" },
  { label: "Retail", value: "retail" },
  { label: "Sales", value: "sales" },
  { label: "Service technician", value: "service-technician" },
  { label: "Software", value: "software" },
  { label: "Staffing & recruiting", value: "staffing-recruiting" },
  { label: "Technology and technical services", value: "technical-services" },
  { label: "Web development", value: "web-development" },
  { label: "Other", value: "other" }
];

const howGotOptions = [
  { label: "Email", value: "email" },
  { label: "Social", value: "social" },
  { label: "Ad", value: "ad" },
  { label: "Online article", value: "online-article" },
  { label: "Friend or peer", value: "friend/peer" },
  { label: "Google Search", value: "google/search" },
  { label: "YouTube", value: "youtube" },
  { label: "G2", value: "g2" },
  { label: "Deel", value: "deel" },
  { label: "Partner", value: "partner" },
  { label: "Other", value: "other" }
];

const OrganizationWizard = () => {
  const history = useHistory();
  const formSchema = Yup.object().shape({
    name: Yup.string().required("can't be blank"),
    haveOne: Yup.boolean(),
    companyWebsite: Yup.string().when("haveOne", {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("can't be blank")
        .matches(urlRegExp, "is not a valid URL. Should be in the format of domain.com or http(s)://domain.com")
    }),
    companySize: Yup.string(),
    industry: Yup.string().required("can't be blank"),
    manager: Yup.string().email("is not a valid email address"),
    howFind: Yup.string()
  });

  const handleSubmit = (values) => {
    const userId = localStorage.getItem('userId');
    const { haveOne, ...data } = values;
    OrganizationApi.create({ userId, values: data }).then(res => history.push(`/organizations/wizard/${res._id}`));
  };

  return (
    <WizardLayout step={0}>
      <S.FormContainer>
        <div className="form-wrapper">
          <h1 className="title">Let's get started!</h1>

          <Formik
            initialValues={{
              name: "",
              haveOne: false,
              companyWebsite: "",
              industry: "",
              manager: "",
              companySize: 'MICRO',
              howFind: ""
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <S.FormControl error={Boolean(errors.name && touched.name)}>
                  <span className="label">What is your organization's name?*</span>

                  <Input
                    size="large"
                    name="name"
                    placeholder="Enter organization name"
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name && touched.name ? errors.name : ""}
                  />
                </S.FormControl>
                <S.FormControl error={Boolean(errors.companyWebsite && touched.companyWebsite)}>
                  <Space style={{ justifyContent: "space-between" }}>
                    <span className="label">Company website{values.haveOne ? "" : "*"}</span>
                    <div className="checkbox">
                      <label className="boolean optional d-flex align-items-center" htmlFor="organization_no_website">
                        <input
                          style={{ marginTop: 0 }}
                          className="boolean optional"
                          type="checkbox"
                          checked={values.haveOne}
                          onChange={handleChange}
                          name="haveOne"
                          id="organization_no_website"
                        />
                        Don't have one
                      </label>
                    </div>
                  </Space>

                  <Input
                    name="companyWebsite"
                    placeholder="Enter company website"
                    value={values.companyWebsite}
                    onChange={handleChange}
                    disabled={values.haveOne}
                    error={errors.companyWebsite && touched.companyWebsite ? errors.companyWebsite : ""}
                  />
                </S.FormControl>
                <S.FormControl>
                  <span className="label">What's the size of your company*</span>
                  <div className="optional-buttons-row">
                    {companySizes.map((size) => (
                      <OptionalButton
                        key={size.value}
                        isActive={size.value === values.companySize}
                        onClick={() => {
                          setFieldValue("companySize", size.value);
                        }}
                      >
                        {size.label}
                      </OptionalButton>
                    ))}
                  </div>
                </S.FormControl>
                <S.FormControl error={Boolean(errors.industry && touched.industry)}>
                  <span className="label">What industry do you work in?*</span>
                  <Select
                    mode="combobox"
                    notFoundContent=""
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    name="industry"
                    placeholder="Select industry"
                    onChange={(value) => setFieldValue("industry", value)}
                    error={errors.industry && touched.industry ? errors.industry : ""}
                  >
                    {industries.map((industry, index) => (
                      <Option key={index} value={industry.value}>
                        {industry.label}
                      </Option>
                    ))}
                  </Select>
                </S.FormControl>
                <S.FormControl error={Boolean(errors.manager && touched.manager)}>
                  <span className="label">Invite a manager to try TimeGo with you</span>
                  <Input
                    type="email"
                    name="manager"
                    placeholder="Enter an email"
                    value={values.manager}
                    onChange={handleChange}
                    error={errors.manager && touched.manager ? errors.manager : ""}
                  />
                </S.FormControl>
                <S.FormControl error={Boolean(errors.howFind && touched.howFind)}>
                  <span className="label">How did you find out about TimeGo?</span>
                  <Select
                    mode="combobox"
                    notFoundContent=""
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    name="howFind"
                    placeholder="Let us know how you got here"
                    onChange={(value) => setFieldValue("howFind", value)}
                    error={errors.howFind && touched.howFind ? errors.howFind : ""}
                  >
                    {howGotOptions.map((industry, index) => (
                      <Option key={index} value={industry.value}>
                        {industry.label}
                      </Option>
                    ))}
                  </Select>
                </S.FormControl>
                <div className="buttons">
                  <DefaultButton>
                    <ArrowBack />
                    Back
                  </DefaultButton>
                  <PrimaryButton htmlType="submit">
                    Next
                    <ArrowForward />
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </S.FormContainer>
    </WizardLayout>
  );
};

export default OrganizationWizard;
