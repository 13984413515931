import styled from "styled-components";

const FormContainer = styled.div`
  width: 100%;
  max-width: 882px;
  padding: 0 20px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 80px;

  .title {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0;
    color: #222e50;
    //font-family: 'Ubuntu', sans-serif;
    font-size: 34px;
  }

  .text {
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    color: #617083;
  }

  .options {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .default {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .welcome-illustration {
    max-width: 425px;
    width: 100%;
  }

  .alternative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #617083;
    width: 100%;
  }

  .paths {
    width: 100%;
    gap: 10px;
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .primary-btn {
    background-color: #2aa7ff;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid #2aa7ff;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 1.45;
    border-radius: 6px;
    user-select: none;
    max-width: 17.3rem;
    margin-bottom: 4rem;
    color: white;
  }

  .primary-btn:hover,
  .primary-btn:focus,
  .primary-btn:active {
    background-color: #1ba1ff;
    border-color: #1ba1ff;
  }

  .outlined-button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 1.45;
    border-radius: 6px;
    user-select: none;
    color: #3e4956;
    background-color: #fff;
    border: 1px solid #e4e9ef;
    //font-family: Roboto,serif;
  }

  .optional-buttons-row {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .outlined-button:hover {
    border-color: #c4cfdc;
  }
  .outlined-button:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  // ${(props) => props.theme.breakpoints.down("xl")} {
  //   max-width: 782px;
  //   margin: 0 80px;
  // }

  ${(props) => props.theme.breakpoints.down("md")} {
    max-width: 600px;
    margin: 0 80px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 0 80px;

    .form-wrapper {
      max-width: 600px;
      margin: 0 20px;
    }

    .title {
      font-size: 24px;
    }

    .paths {
      flex-direction: row;
    }
  }
`;

const FormControl = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .label {
    //font-family: Ubuntu,serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: initial;
    color: #2d3137;
    margin-bottom: 5px;
  }

  > div {
    width: 100%;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    flex-direction: column;

    .label {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
`;

export { FormContainer, FormControl };
