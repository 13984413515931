import styled from "styled-components";

const Container = styled.div`
  .title {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0;
    color: #222e50;
    //font-family: 'Ubuntu', sans-serif;
    font-size: 34px;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    margin-bottom: 20px;
    color: #617083;
  }

  .note {
    color: #617083;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .preferred-ribbon {
    font-size: 14px;
    height: 30px;
    width: 34px;
    border-radius: 0 6px 6px 0;
    background-color: #d6dbe6;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 50%;
    right: 82%;
    color: red;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .preferred-ribbon::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    border-style: solid;
    border-width: 0 10px 8px 0;
    border-color: transparent #607083 transparent transparent;
  }
  .integrations {
    margin-bottom: 30px;
    .subtitle {
      font-size: 18px;
      font-weight: 400;
      text-transform: initial;
      color: #2d3137;
      max-width: 100%;
      margin-bottom: 5px;
    }
    .options {
      display: flex;
      flex-direction: row !important;
      flex-wrap: wrap;
      gap: 20px 13px;
      justify-content: start;
      margin-bottom: 14px;
    }
  }
`;

export { Container };
