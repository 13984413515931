
import * as types from "../actionTypes";
import { IMemberInterface } from "../../components/Dashboard/Team/AddTeamModal";

const initialValue: {
  members: IMemberInterface[],
} = {
  members: [],
}

const memberReducer = (state = initialValue, action: any) => {
  switch(action.type) {
    case types.SET_MEMBERS: {
      return {
        ...state,
        members: action.payload,
      }
    }
    default:
      return state;
  }
}
export default memberReducer;
