import * as types from '../actionTypes';
import { ITaskInterface } from "../../components/Dashboard/Todo/AddTaskModal";

export const setTasks = (value: ITaskInterface[]) => {
  return {
    type: types.SET_TASKS,
    payload: value,
  }
}

export const setTask = (value: ITaskInterface | null) => {
  return {
    type: types.SET_TASK,
    payload: value,
  }
}
