import Http from "./http";
import { IInvoiceInterface } from "../pages/Dashboard/Financials/Invoices/CreateInvoice";

export const create = (value: IInvoiceInterface) => {
  return Http.post('/invoice', value);
}

export const getInvoices = (dateRange: { startDate: Date, endDate: Date }) => {
  return Http.get('/invoice/all', dateRange);
}

export const getById = (id: string) => {
  return Http.get(`/invoice/${id}`);
}

export const update = ({ id, data }: { id: string, data: IInvoiceInterface }) => {
  return Http.patch(`/invoice/${id}`, data);
}

export const updateStatus = ({ selectedRows, status }: { selectedRows: string[], status: string }) => {
  return Http.patch('/invoice/status', { selectedRows, status });
}

export const deleteById = (id: string) => {
  return Http.delete(`/invoice/${id}`);
}
