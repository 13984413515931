import React, { useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setProject, setProjects } from "../../../../store/actions/project.actions";
import { Table, Tag, Tooltip } from 'antd'

import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { getProjects } from "../../../../store/selectors";
import { TableContainer } from '../../../../components/Dashboard/CommonStyled'
import { MemberList } from "../../Team/TeamTable";
import _, { map } from 'lodash'
import TableActionMenu from '../../../../components/Dashboard/TableActionMenu'
import moment from "moment-timezone";
import * as ProjectApi from "../../../../apis/project.api";
import UserImg from "../../../../assets/images/dashboard/user-avatar.png";

interface DataType {
  key: React.Key
  name: string
  teams: string[]
  members: string
  budget: string
  track: string
  status: string
  icon?: string | React.ReactNode
}

const TeamList = styled.div`
  display: flex;

  .team {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #549a58;
    color: white;
    margin-right: 10px;
  }
`

const Name = styled.div`
  display: flex;
  align-items: center;
  color: #53aaff;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    background-color: #82b55e;
    margin-right: 10px;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`

const ApprovalTable = ({ searchValue, setIsAddProjectOpen }) => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, row) => <Name>
        <div className='avatar'>
          {name[0]}
        </div>
        {name}
        { row.icon &&
            <img src={row.icon} alt={name} />
        }
      </Name>
    },
    {
      title: 'Teams',
      dataIndex: 'teams',
      render: teams => (
        <TeamList>
          {
            teams.map((team, index) => (
              <Tooltip placement='top' title={_.upperFirst(team)} key={index} >
                <div className='team'>{_.upperFirst(team.slice(0, 1))}</div>
              </Tooltip>
            ))
          }
        </TeamList>
      )
    },
    {
      title: 'Managers',
      dataIndex: 'managers',
      render: (managers) => (
        <MemberList>
          { managers.map((member, index) => (
            <div key={index} style={{ display: 'flex', marginTop: 3, marginBottom: 3 }}>
              <img src={UserImg} alt='member' style={{ marginRight: 3 }} />
              <div>{member.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Developers',
      dataIndex: 'developers',
      render: (developers) => (
        <MemberList>
          { developers.map((member, index) => (
            <div key={index} style={{ display: 'flex', marginTop: 3, marginBottom: 3 }}>
              <img src={UserImg} alt='member' style={{ marginRight: 3 }} />
              <div>{member.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Budget',
      dataIndex: 'cost',
      render: (cost) => {
        return (
          <div>{cost}{!!cost && '$'}</div>
        )
      }
    },
    {
      title: 'Trackable by me',
      dataIndex: 'basedOn',
      render: (basedOn) => {
        return (
          <div>{_.upperFirst(basedOn).replace('_', ' ')}</div>
        )
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (startDate) => {
        return (
          <div>{moment(new Date(startDate)).format('DD/MM/YYYY')}</div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: status => <Tag color="green-inverse">{status}</Tag>
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row) => <TableActionMenu row={row} handleDeleteRow={handleDeleteRow} handleEditRow={handleEditRow} />
    }
  ];

  const tableData = useMemo(() => {
    if (projects.length > 0) {
      return projects.map((item) => (
        {
          ...item,
          key: item.id,
          status: item.disableActivity ? 'Active' : 'Inactive'
        }
      ));
    }
  }, [projects]);

  const handleDeleteRow = async (id: string) => {
    await ProjectApi.deleteById(id);
    ProjectApi.getAll(searchValue).then((res) => dispatch(setProjects(res)));
  };

  const handleEditRow = async (id: string) => {
    await ProjectApi.getById(id).then((res) => dispatch(setProject(res)));
    setIsAddProjectOpen(true);
  };

  return (
    <TableContainer>
      {
        tableData &&
        tableData.length > 0 ? (
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{x: 'max-content'}}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={[]}
            pagination={false}
            scroll={{x: 'max-content'}}
          />
        )
      }
      <Box mt={2}>
        <p>Showing { tableData?.length } of { tableData?.length } { tableData?.length === 1 ? 'project' : 'projects' }</p>
      </Box>
    </TableContainer>
  )
}

export default ApprovalTable
