import * as types from "../actionTypes";
import { ITimeSheetInterface } from "../actions/timesheet.action";

const initialValue: {
  timeSheets: ITimeSheetInterface[],
  timeSheet: ITimeSheetInterface | null,
} = {
  timeSheets: [],
  timeSheet: null,
}

const timeSheetReducer = (state = initialValue, action: any) => {
  switch(action.type) {
    case types.SET_TIMESHEETS: {
      return {
        ...state,
        timeSheets: action.payload,
      }
    }
    case types.SET_TIMESHEET: {
      return {
        ...state,
        timeSheet: action.payload,
      }
    }
    default:
      return state;
  }
}
export default timeSheetReducer;
