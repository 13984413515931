import * as types from '../actionTypes'
import { IProjectInterface } from "../../components/Dashboard/Poject/AddProjectModal";

const initialState: {
  projects: IProjectInterface[];
  project: IProjectInterface | null;
} = {
  projects: [],
  project: null
}

const projectReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_PROJECTS:
      return {
        ...state,
        projects: action.payload
      };
    case types.SET_PROJECT:
      return {
        ...state,
        project: action.payload
      }
    default:
      return state;
  }
}

export default projectReducer
