import React, { useEffect, useMemo, useState } from "react";
import { getProjects } from "../../../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";

import { Box } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Tabs } from "../../../../components/Dashboard/CommonStyled";
import { Container } from "./styles";
import { Tooltip } from "antd";
import { setProjects } from "../../../../store/actions/project.actions";
import Button from "../../../../components/Dashboard/Button";
import ProjectTable from "./ProjectTable";
import Input from "../../../../components/Dashboard/Input";
import AddProjectModal from "../../../../components/Dashboard/Poject/AddProjectModal";
import * as ProjectApi from "../../../../apis/project.api";

const { TabPane } = Tabs;

const useStyles = makeStyles(() => ({
  search: {
    maxWidth: 400,
    width: 400,
    borderRadius: "30px !important",
  },
}));

const Projects = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const projects = useSelector(getProjects);
  const [isAddProjectOpen, setIsAddProjectOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    ProjectApi.getAll(searchValue).then((res) => dispatch(setProjects(res)));
  }, [searchValue]);

  const activeCount = useMemo(() => {
    return projects.filter((project) => project.disableActivity).length;
  }, [projects]);

  return (
    <Container>
      <Box mb={2}>
        <h1>Projects</h1>
      </Box>
      <Box mt={4}>
        <Tabs defaultActiveKey="1">
          <TabPane tab={`ACTIVE (${activeCount})`} key="1">
            <Box dir="row" pt={3} pb={5} pl={1} className="search-wrapper">
              <Input
                className="search"
                prefix={<Search />}
                placeholder="Search projects"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Box className="actions-row">
                <Button className="primary-outlined">Import projects</Button>
                <Tooltip
                  className="tooltip"
                  title="Add new project to the organization"
                  placement="left"
                >
                  <Button
                    className="primary-contained"
                    onClick={() => setIsAddProjectOpen(true)}
                  >
                    Add project
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            <Box pt={3}>
              <ProjectTable
                searchValue={searchValue}
                setIsAddProjectOpen={setIsAddProjectOpen}
              />
            </Box>
          </TabPane>
          <TabPane tab="ARCHIVED (0)" key="2"></TabPane>
        </Tabs>
      </Box>
      <AddProjectModal
        isOpen={isAddProjectOpen}
        onClose={() => setIsAddProjectOpen(false)}
        searchValue={searchValue}
      />
    </Container>
  );
};

export default Projects;
