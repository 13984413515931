import * as types from '../actionTypes';
import { IInvoiceInterface } from "../../pages/Dashboard/Financials/Invoices/CreateInvoice";

export const setInvoiceDraft = (value: IInvoiceInterface | null) => {
  return {
    type: types.SET_INVOICE_DRAFT,
    payload: value,
  }
}

export const setInvoices = (value: IInvoiceInterface[]) => {
  return {
    type: types.SET_INVOICES,
    payload: value
  }
}

export const setInvoice = (value: IInvoiceInterface | null) =>　{
  return {
    type: types.SET_INVOICE,
    payload: value
  }
}
