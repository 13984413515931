import { IInvoiceInterface } from "../../pages/Dashboard/Financials/Invoices/CreateInvoice";
import * as types from "../actionTypes";

const initialValue: {
  draft: IInvoiceInterface | null;
  invoices: IInvoiceInterface[];
  invoice: IInvoiceInterface | null;
} = {
  draft: null,
  invoices: [],
  invoice: null,
}

const InvoiceReducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case types.SET_INVOICE_DRAFT: {
      return {
        ...state,
        draft: action.payload
      }
    }
    case types.SET_INVOICES: {
      return {
        ...state,
        invoices: action.payload
      }
    }
    case  types.SET_INVOICE: {
      return {
        ...state,
        invoice: action.payload
      }
    }
    default:
      return state;
  }
}
export default InvoiceReducer;
