import React from "react";
import styled, { css } from "styled-components";
import { Input as AInput } from "antd";
import { TextAreaProps } from "antd/lib/input";

const ATextArea = AInput.TextArea;

interface Props extends TextAreaProps {
  label?: string;
  error?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  span.label {
    color: ${(props) => props.theme.palette.dashboard.textLight};
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  svg {
    color: ${(props) => props.theme.palette.dashboard.textPrimary};
  }
`;

const StyledTextArea = styled(ATextArea)<{ error?: string; size?: string }>`
  border: 1px solid ${(props) => props.theme.colors.gray100} !important;
  border-radius: 6px !important;
  padding: 12px !important;

  &::placeholder {
    color: #757575 !important;
  }
  &:focus {
    border-color: #40a9ff !important;
    box-shadow: rgba(0, 123, 255, 0.25) 0 0 0 2px;
  }
  ${(props) =>
    props.error &&
    css`
      border-color: red !important;
    `}
`;

const ErrorText = styled.p`
  margin: 2px 0 0 0 !important;
  font-size: 12px !important;
  color: red !important;
  line-height: 20px !important;
`;

const TextArea = (props: Props) => {
  const { label, ...rest } = props;

  return (
    <Container>
      {label && <span className="label">{label}</span>}
      <StyledTextArea {...rest} />
      {props.error && <ErrorText>{props.error}</ErrorText>}
    </Container>
  );
};

export default TextArea;
