import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { getClients } from "../../../../store/selectors";
import { Switch, Tooltip } from "antd";
import { InfoOutlined } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import { FormikProps } from "formik";
import { IProjectInterface } from "./index";
import TextArea from "../../../Common/TextArea";
import CustomSelect from "../../../Common/Select/Select";
import * as S from "./style";

const GeneralTab = ({ ...props }: FormikProps<IProjectInterface>) => {
  const clients = useSelector(getClients);

  const clientOptions = useMemo(() => {
    return clients.map((client) => ({
      value: client.id,
      label: client.name,
    }))
  }, [clients]);

  return (
    <Box>
      <S.FormControl>
        <span className="label">
          <span className="switch-label">
            Project NAMES*{" "}
            <Tooltip placement='right' title="Create multiple projects by adding project names on separate lines">
              <InfoOutlined />
            </Tooltip>
          </span>
        </span>
        <TextArea
          name="name"
          placeholder="Add proejct names separated by new lines"
          value={props.values.name}
          onChange={(e) => props.setFieldValue('name', e.target.value)}
          error={props.errors.name || props.touched.name ? props.errors.name : ""}
        />
      </S.FormControl>
      <S.FormControl>
        <span className="switch-label">
          <Switch
            checked={props.values.billable}
            onChange={(e) => props.setFieldValue('billable', e)}
          />
          Billable
          <Tooltip placement='right' title="When enabled, this will no longer record activity for any members tracking time to this project. This is oftend used on any projects related to calls or meetings.">
            <InfoOutlined />
          </Tooltip>
        </span>
      </S.FormControl>
      <S.FormControl>
        <span className="switch-label">
          <Switch
            checked={props.values.disableActivity}
            onChange={(e) => props.setFieldValue('disableActivity', e)}
          />
          Disable activity
          <Tooltip placement='right' title="When enabled, this will no longer record activity for any members tracking time to this project. This is oftend used on any projects related to calls or meetings.">
            <InfoOutlined />
          </Tooltip>
        </span>
      </S.FormControl>
      <S.FormControl>
        <span className="switch-label">
          <Switch
            checked={props.values.disableIdleTime}
            onChange={(e) => props.setFieldValue('disableIdleTime', e)}
          />
          Disable idle time
          <Tooltip placement='right' title="When enabled, this will no longer record activity for any members tracking time to this project. This is oftend used on any projects related to calls or meetings.">
            <InfoOutlined />
          </Tooltip>
        </span>
      </S.FormControl>
      <S.FormControl>
        <CustomSelect
          label="CLIENT"
          name="client"
          value={props.values.client}
          onChange={(e) => props.setFieldValue('client', { value: e?.value, label: e?.label })}
          placeholder="Select a client"
          options={clientOptions}
          styles={{
            control: (style) => ({ ...style, width: "100%" })
          }}
          onClear={() => props.setFieldValue('client', null)}
        />
      </S.FormControl>
    </Box>
  )
};

export default GeneralTab;