import * as types from '../actionTypes';
import { ITaskInterface } from "../../components/Dashboard/Todo/AddTaskModal";

const initialValue: {
  tasks: ITaskInterface[];
  task: ITaskInterface | null;
  loading: boolean;
} = {
  tasks: [],
  task: null,
  loading: false,
}

const taskReducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case types.SET_TASKS: {
      return {
        ...state,
        loading: true,
        tasks: action.payload
      }
    }
    case types.SET_TASK: {
      return {
        ...state,
        task: action.payload,
      }
    }
    default:
      return state;
  }
}
export default taskReducer;
