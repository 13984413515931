import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMembers, getProjects } from "../../../../store/selectors";
import { setMembers } from "../../../../store/actions/member.action";
import MemberSelector from "../../../Common/MemberSelector";
import CustomSelect from "../../../Common/Select/Select";
import * as S from "./style";
import * as ProjectApi from "../../../../apis/project.api";

const MembersTab = (props) => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const members = useSelector(getMembers);

  const projectOptions = useMemo(() => {
    return projects.map((project) => (
      {
        value: project.id,
        label: project.name,
      }
    ))
  }, [projects]);

  useEffect(() => {
    if (props.values.project?.value) {
      ProjectApi.getMembersByProject(props.values.project.value).then((res) => dispatch(setMembers(res)));
    } else {
      dispatch(setMembers([]));
    }
  }, [props.values.project]);

  const handleClear = () => {
    props.setFieldValue('project', null);
    dispatch(setMembers([]));
  };

  return (
    <>
      <S.FormControl>
        <CustomSelect
          label='Project'
          name='project'
          options={projectOptions}
          placeholder='Select a project'
          styles={{
            control: (style) => ({ ...style, width: '100%' })
          }}
          value={props.values.project}
          onChange={(e) => props.setFieldValue('project', { value: e?.value, label: e?.label })}
          onClear={handleClear}
        />
      </S.FormControl>
      <MemberSelector
        members={members}
        label="DEVELOPER"
        description="choose the developer to carry out the task"
        { ...props }
      />
    </>
  )
}
export default MembersTab;
