import React, { useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setInvoice, setInvoices } from "../../../../store/actions/invoice.action";
import { getInvoices } from "../../../../store/selectors/invoice.selector";
import TableActionMenu from "../../../../components/Dashboard/TableActionMenu";
import { Box } from '@material-ui/core'
import { TableContainer } from '../../../../components/Dashboard/CommonStyled'
import { Name } from "../../Team/TeamTable";
import { Table } from 'antd'
import moment from "moment-timezone";
import * as InvoiceApi from "../../../../apis/invoice.api";
import _ from "lodash";

const InvoiceTable = ({ setSelectedRows, dateRange }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const invoices = useSelector(getInvoices);

  const rowSelection = {
    onChange: (selectedRow: any) => {
      setSelectedRows(selectedRow);
    }
  };

  const columns = [
    {
      title: 'Invoice',
      dataIndex: 'payer',
      render: (payer) => (
        <Name>
          <div className='avatar'>
            {payer.name[0]}
          </div>
          {payer.name}
        </Name>
      )
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (client) => (
        <Name>
          <div className='avatar'>
            {client.name[0]}
          </div>
          {client.name}
        </Name>
      )
    },
    {
      title: 'Issue date',
      dataIndex: 'issueDate',
      render: (date: Date) => (
        <div>{moment(new Date(date)).format('DD/MM/YYYY')}</div>
      )
    },
    {
      title: 'Days old',
      dataIndex: 'daysOld',
      render: (days: string) => (
        <div>{days}</div>
      )
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (total: number) => (
        <div>{total.toString().concat('$')}</div>
      )
    },
    {
      title: 'Paid',
      dataIndex: 'grandTotal',
      render: (paid: number) => (
        <div>{paid.toString().concat('$')}</div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <div
          style={{
            backgroundColor: statusColor(status),
            padding: '5px',
            borderRadius: '5px',
            textAlign: 'center',
            color: 'white'
          }}
        >
          {_.upperFirst(status).replace('_', ' ')}
        </div>
      )
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row) => <TableActionMenu row={row} handleEditRow={handleEditRow} handleDeleteRow={handleDeleteRow}/>
    }
  ];

  const statusColor = (status: string) => {
    switch (status) {
      case 'invoiced': {
        return 'blue'
      }
      case 'uninvoiced': {
        return 'red'
      }
      case 'paid': {
        return 'green'
      }
      case 'not_billable': {
        return 'lightgrey'
      }
    }
  }

  const tableData = useMemo(() => {
    return invoices.map((invoice) => (
      {
        ...invoice,
        key: invoice.id,
        daysOld: moment(invoice.dueDate).from(invoice.issueDate),
      }
    ))
  }, [invoices]);

  const handleEditRow = async (id: string) => {
    await InvoiceApi.getById(id).then((res) => dispatch(setInvoice(res)));
    history.push('/dashboard/financials/invoices/new');
  };

  const handleDeleteRow = async (id: string) => {
    await InvoiceApi.deleteById(id);
    InvoiceApi.getInvoices(dateRange).then((res) => dispatch(setInvoices(res)));
  };

  return (
    <TableContainer>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
      <Box mt={2}>
        <p>{ tableData.length } { tableData.length > 1 ? 'invoices' : 'invoice' }</p>
      </Box>
    </TableContainer>
  )
}

export default InvoiceTable