import Http from "./http";
import { ITaskInterface } from "../components/Dashboard/Todo/AddTaskModal";
import { IFilterParam } from "../pages/Dashboard/ProjectManagement/Todos";

export const create = (value: ITaskInterface) => {
  return Http.post('/task', value);
}

export const getAll = (filterParam?: IFilterParam) => {
  return Http.get('/task/all', filterParam);
}

export const getById = (id: string) => {
  return Http.get(`/task/${id}`);
}

export const getDuplicatedProjects = (duplicated: boolean) => {
  return Http.get('/task/duplicate', { duplicated });
}

export const update = ({ id, data }: { id: string, data: ITaskInterface }) => {
  return Http.patch(`/task/${id}`, data);
}

export const deleteById = (id: string) => {
  return Http.delete(`/task/${id}`);
}
