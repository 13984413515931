import React, { useEffect, useMemo } from "react";
import { setTask, setTasks } from "../../../../store/actions/task.action";
import { useDispatch, useSelector } from "react-redux";
import { getTask } from "../../../../store/selectors/task.selector";
import { setMembers } from "../../../../store/actions/member.action";
import { Form, Formik, FormikProps } from "formik";
import { Tabs } from "antd";
import { IFilterParam } from "../../../../pages/Dashboard/ProjectManagement/Todos";
import Modal from "../../Modal";
import * as S from "./style";
import * as Yup from "yup";
import GeneralTab from "./GeneralTab";
import MembersTab from "./MembersTab";
import TaskTime from "./TaskTime";
import Button from "../../Button";
import * as TaskApi from "../../../../apis/task.api";
import * as MemberApi from "../../../../apis/member.api";

const { TabPane } = Tabs;

export interface ITaskInterface {
  id?: string;
  name: string;
  project: {
    value: string,
    label: string
  };
  developer: string[];
  startDate: Date;
  duration: number;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  filterParam: IFilterParam
}

const AddTaskModal = ({ onClose, open, filterParam }) => {
  const dispatch = useDispatch();
  const task = useSelector(getTask);

  useEffect(() => {
    if (!open) {
      dispatch(setTask(null));
      MemberApi.getAll().then((res) => dispatch(setMembers(res)));
    }
  }, [open]);

  const taskSchema = Yup.object().shape({
    name: Yup.string().required('can`t be blank'),
    project: Yup.object().required('can`t be blank'),
    developer: Yup.array().min(1).required(),
    startDate: Yup.date().required(),
    duration: Yup.number()
  });

  const initialValue  = useMemo(() => {
    return {
      name: task?.name || '',
      project: task?.project || null,
      developer: task?.developer || [],
      startDate: task?.startDate || new Date(),
      duration: task?.duration || 0,
    };
  }, [task]);

  const handleSubmit = async (value: ITaskInterface) => {
    if (task?.id) {
      await TaskApi.update({
        id: task.id,
        data: {
          ...value,
          project: value.project.value as any,
        }
      });
    } else {
      await TaskApi.create({
        ...value,
        project: value.project.value as any
      });
    }
    TaskApi.getAll(filterParam).then((res) => dispatch(setTasks(res)));
    onClose()
  }

  return (
    <Modal title="Add task" onClose={onClose} open={open}>
      <S.ModalContainer>
        <Formik
          initialValues={initialValue}
          validationSchema={taskSchema}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<ITaskInterface>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className="tab-content">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="GENERAL" key="1">
                    <GeneralTab { ...props } />
                  </TabPane>
                  <TabPane tab="MEMBERS" key="2">
                    <MembersTab data={task} { ...props } />
                  </TabPane>
                  <TabPane tab="TASKTIME" key="3">
                    <TaskTime { ...props } />
                  </TabPane>
                </Tabs>
              </div>
              <div className="modal-footer">
                <Button className="gray-outlined" onClick={onClose}>Cancel</Button>
                <Button
                  htmlType='submit'
                  className="primary-contained"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </S.ModalContainer>
    </Modal>
  )
}
export default AddTaskModal;
