import React from "react";
import { useSelector } from "react-redux";
import { getMembers } from "../../../../store/selectors";
import MemberSelector from "../../../Common/MemberSelector";

const MembersTab = (props) => {
  const members = useSelector(getMembers);

  return (
    <>
      <MemberSelector
        members={members}
        label="MANAGERS"
        description="Oversees and manages the team"
        { ...props }
      />
      <MemberSelector
        members={members}
        label="MEMBERS"
        description="Works on the team, will not see other users (most common)"
        { ...props }
      />
    </>
  )
}
export default MembersTab;
