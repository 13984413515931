import Http from "./http";
import { IFilterInterface } from "../pages/Dashboard/TimeSheets/ViewAndEdit";

export const create = (value) => {
  return Http.post('/timesheet', value);
}

export const getTimeSheets = (filterParams: IFilterInterface) => {
  return Http.get('/timesheet/all', filterParams);
}

export const getById = (id: string) => {
  return Http.get(`/timesheet/${id}`);
}

export const update = ({ id, data }: { id: string, data }) => {
  return Http.patch(`/timesheet/${id}`, data);
}

export const stopTime = ({ id, value, filterParams }: { id: string, value: any, filterParams: IFilterInterface}) => {
  return Http.patch(`/timesheet/${id}/stop`, { value, filterParams });
}

export const deleteById = (id: string) => {
  return Http.delete(`/timesheet/${id}`);
}
