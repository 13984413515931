import React, { useEffect, useState } from "react";
import { XCircle } from "react-feather";
import * as S from "./style";
import SearchInput from "../SearchInput";
import clsx from "clsx";
import EmptyBoxImage from '../../../assets/images/empty_box.svg';

export interface IMember {
  name: string;
  email: string;
  checked?: boolean;
}

interface Props {
  label?: string;
  description?: string;
  members: IMember[];
}

const MemberSelector = (props) => {
  const { label, description, members, data } = props;
  const [isShowOnlySelected, setIsShowOnlySelected] = useState(false);
  const [modifiedMembers, setModifiedMembers] = useState(members);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  useEffect(() => {
    props.setFieldValue(`${label.toLowerCase()}`, modifiedMembers.filter((member) => member.checked).map((item) => item.id));
  }, [modifiedMembers]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (data !== null) {
      setModifiedMembers(
        members.map((member) => (
          props.values[`${label.toLowerCase()}`].find((item) => member.id === item.id) ? (
            {
              ...member,
              checked: true,
            }
          ) : (
            {
              ...member,
            }
          )
        ))
      );
    } else {
      setModifiedMembers(members)
    }
  }, [members, data]);

  const handleSelectMember = (e, member) => {
    e.preventDefault();
    e.stopPropagation();
    const newMembers = modifiedMembers.map(item => item.email === member.email ? {...item, checked: !item.checked} : item)
    setModifiedMembers(newMembers);
  };

  const handleSelectAll = () => {
    setModifiedMembers(modifiedMembers.map(item => ({...item, checked: true})))
  }

  const handleClear = (e) => {
    e.stopPropagation();
    setModifiedMembers(modifiedMembers.map(item => ({...item, checked: false})))
  }

  const isAllSelected = modifiedMembers.every(item => !!item.checked);

  return (
    <S.SelectorWrapper>
      <div className="app-members-selector-title">
        <label className="control-label">{label}</label>
        <div className="label-secondary">
          <span>{description}</span>
        </div>
      </div>

      <S.SelectSelection>
        {modifiedMembers.filter(member => !!member.checked).length > 0 ?
          <div onClick={handleClick} className="app-select" >
            <div data-select2-ignore="" className="select-container">
              <button onClick={(e) => handleClear(e)} className="clear-all-trigger">
                <XCircle className="search-clear" />
              </button>
              <div className="selection">
                <div className="selection-counter">
                  {modifiedMembers.filter(member => !!member.checked).length} &nbsp;
                  {modifiedMembers.filter(member => !!member.checked).length === 1 ? 'member' : 'members'}
                </div>
              </div>
            </div>
          </div>
          :
          <div onClick={handleClick} className="app-selector">Select members</div>
        }
      </S.SelectSelection>
      <S.PopoverContainer
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        style={{ borderRadius: 8 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <S.PopoverContent>
          <div className="lazy-select-content-header d-flex flex-column pt-15">
            <SearchInput
              onChange={(e) => setSearchKeyword(e.target.value)}
              value={searchKeyword}
              placeholder="Search members"
              onClear={() => setSearchKeyword('')}
            />
          </div>
          <div className="lazy-select-content-results">
            <div className="lazy-select-content-results-list pb-30">
              {modifiedMembers.filter(member => member.name.includes(searchKeyword)).length > 0 && <div className="">
                <div
                  className={clsx("lazy-select-content-results-list-item select-all-trigger", isAllSelected && "disabled")}
                  onClick={handleSelectAll}>
                  <label className="custom-checkbox-wrapper text-blue">
                    <strong>Select all</strong>
                  </label>
                </div>
              </div>}
              <div role="group" className="" style={{ padding: 0 }}>
                <div role="listitem" className="">
                  {modifiedMembers.filter(member => member.name.includes(searchKeyword)).length > 0 ?
                    modifiedMembers.filter(member => member.name.includes(searchKeyword))
                      .map(member =>
                        <div
                          key={member.id}
                          className={clsx("lazy-select-content-results-list-item", member.checked && 'selected', isShowOnlySelected && !member.checked && 'hidden')}
                          onClick={(e) => handleSelectMember(e, member)}
                        >
                          <label className="custom-checkbox-wrapper">
                            <input type="checkbox" checked={member.checked} />
                            <span className="custom-checkbox-box"></span>
                            <span className="check-box-label">
                            <div
                              className="lazy-select-content-results-list-item-content d-flex align-items-center w-full pr-10">
                              <img
                                src="https://server-assets.hubstaff.com/71a847ac32/hubstaff_auth/profile/default_speck-ab4a84406b7db77dc05ecef182d9a5879abff96e6bdaf7fd12713d5c2a06b36c.png"
                                height="25"
                                width="25"
                                className="avatar avatar-speck"
                                alt="" />
                              <p className="lazy-select-content-results-list-item-content-name">
                                {member.name}
                              </p>
                            </div>
                          </span>
                          </label>
                        </div>
                      ):
                    <S.EmptyBox>
                      <img src={EmptyBoxImage} />
                      No members found
                    </S.EmptyBox>
                  }
                </div>
              </div>
              <div style={{ width: "100%", height: 0 }}></div>
            </div>
          </div>
          <div className="lazy-select-content-footer d-flex align-items-center px-15 py-10">
            <label onClick={(e) => {
              e.preventDefault();
              modifiedMembers.some(item => !!item.checked) && setIsShowOnlySelected(!isShowOnlySelected);
            }} className="toggle-switch-wrapper text-muted">
              <input type="checkbox" checked={isShowOnlySelected} autoComplete="off" disabled={!modifiedMembers.some(item => !!item.checked)} />
              <div className="toggle-switch"></div>
              <span className="toggle-switch-label">
                  Show only selected
              </span>
            </label>
          </div>
        </S.PopoverContent>
      </S.PopoverContainer>

    </S.SelectorWrapper>
  );
};

export default MemberSelector;
