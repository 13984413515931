import React, { useState } from "react";
import { Box, CssBaseline, useMediaQuery } from "@material-ui/core";

import { Link } from "react-router-dom";
import { Steps } from "antd";
import * as S from "./styles";
import { Logo } from "../../components/Common/Icons";
import styled, { createGlobalStyle } from "styled-components/macro";

import CheckIcon from "@material-ui/icons/Check";
import { RadioButtonUnchecked } from "@material-ui/icons";

import AdjustIcon from "@material-ui/icons/Adjust";
import Stepper from "../../components/Organization/Stepper";

const Step = Steps.Step;

const Root = styled.div`
  width: 100%;
  height: 100vh;
`;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: white;
  }
`;

const OrganizationsWizardLayout = ({ children }) => {
  const isScreenSmallerThan720px = useMediaQuery("(max-width:720px)");
  const [status, setStatus] = useState(0);
  const getIcons = (value: number) => {
    return status > value ? (
      <div className="check-icon">
        <CheckIcon style={{ color: "white" }} width={6} height={6} color="inherit" />
      </div>
    ) : status < value ? (
      <RadioButtonUnchecked style={{ color: "white" }} />
    ) : (
      <AdjustIcon style={{ color: "white" }} />
    );
  };
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
};

export default OrganizationsWizardLayout;
