import { ITeamInterface } from "../../components/Dashboard/Team/AddTeamModal";
import * as types from "../actionTypes";

const initialValue: {
  teams: ITeamInterface[],
  team: ITeamInterface | null,
} = {
  teams: [],
  team: null,
}

const teamReducer = (state = initialValue, action: any) => {
  switch(action.type) {
    case types.SET_TEAMS: {
      return {
        ...state,
        teams: action.payload,
      }
    }
    case types.SET_TEAM: {
      return {
        ...state,
        team: action.payload,
      }
    }
    default:
      return state;
  }
}
export default teamReducer;
