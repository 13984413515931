import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTimeSheet } from "../../../../store/selectors";
import { Box, Grid } from "@material-ui/core";
import { FormikProps } from "formik";
import { ITimeSheetInterface } from "./index";
import { timeStyle } from "../../../../pages/Dashboard/TimeSheets/ViewAndEdit/timeCalculator";
import Input from "../../../Common/Input";
import moment from "moment";

const DateDurationTab = ({ ...props }: FormikProps<ITimeSheetInterface>) => {
  const [hour, setHour] = useState<string>('');
  const [minute, setMinute] = useState<string>('');
  const [second, setSecond] = useState<string>('');

  const timesheet = useSelector(getTimeSheet);

  useEffect(() => {
    const time = new Date(props.values.endDate).getTime() - new Date(props.values.startDate).getTime();

    if (time > 0)
      props.setFieldValue('duration', (time / 1000 + 1).toFixed(0));
    else
      props.setFieldValue('duration', 0);

  }, [props.values]);

  useEffect(() => {
    setHour(timeStyle(props.values.duration).hour);
    setMinute(timeStyle(props.values.duration).minute);
    setSecond(timeStyle(props.values.duration).second);
  }, [props.values.duration]);

  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <span
            style={{
              fontWeight: 500,
              color: '#79899d',
              textTransform: 'uppercase',
              fontSize: '11px',
            }}
          >
            Start Date
          </span>
          <Input
            name='startDate'
            type='datetime-local'
            value={moment(props.values.startDate).format('YYYY-MM-DDTHH:mm:ss')}
            onChange={(e) => props.setFieldValue('startDate', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <span
            style={{
              fontWeight: 500,
              color: '#79899d',
              textTransform: 'uppercase',
              fontSize: '11px',
            }}
          >
            End Date
          </span>
          <Input
            name='endDate'
            type='datetime-local'
            value={moment(props.values.endDate).format('YYYY-MM-DDTHH:mm:ss')}
            onChange={(e) => props.setFieldValue('endDate', e.target.value)}
            disabled={timesheet && timesheet.progressing}
          />
        </Grid>
        <Grid item xs={12}>
          <span
            style={{
              fontWeight: 500,
              color: '#79899d',
              textTransform: 'uppercase',
              fontSize: '11px',
            }}
          >
            Duration
          </span>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Box display='flex' alignItems='center'>
                <Input
                  type='number'
                  value={hour}
                  disabled={timesheet && timesheet.progressing}
                />
                <span style={{ fontSize: 20, marginLeft: 8 }}>:</span>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display='flex' alignItems='center'>
                <Input
                  type='number'
                  value={minute}
                  disabled={timesheet && timesheet.progressing}
                />
                <span style={{ fontSize: 20, marginLeft: 8 }}>:</span>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Input
                type='number'
                value={second}
                disabled={timesheet && timesheet.progressing}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
export default DateDurationTab;
