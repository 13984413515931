import * as types from '../actionTypes'
import { IProjectInterface } from "../../components/Dashboard/Poject/AddProjectModal";

export const setProjects = (value: IProjectInterface[]) => {
  return {
    type: types.SET_PROJECTS,
    payload: value
  }
}

export const setProject = (value: IProjectInterface | null) => {
  return {
    type: types.SET_PROJECT,
    payload: value
  }
}
