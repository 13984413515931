import React, { FC, ReactNode } from "react";
import moment from 'moment'
import { addDays } from 'date-fns';
import { Calendar } from 'react-date-range'
import styled, { css } from "styled-components";
import { CalendarToday as CalendarIcon, ArrowForward, ArrowBack } from '@material-ui/icons'
import { Dropdown, Menu } from 'antd'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Box } from "@material-ui/core";

export interface IDatePickerProps {
  label?: ReactNode
  date?: Date
  setDate: (value: Date) => void
  showActionButtons?: boolean
  isCustom?: boolean
}

const useStyles = makeStyles(() => ({
  dropdown: {
    marginTop: 15,
    width: 'fit-content'
  }
}))

const Container = styled.div<{isCustom?: boolean}>`
  display: inline-flex;
  height: 38px;
    ${props => props.isCustom && css`
            width: 100%;
      `}

  .button {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    transition: all ease 0.3s;

    svg {
      color: ${props => props.theme.palette.dashboard.textPrimary};
      transition: all ease 0.3s;
      font-size: 20px;
    }

    &:hover {
      svg {
        color: ${props => props.theme.colors.blue50};
      }
    }

    &.disabled {
      background-color: #f4f5f8;
      pointer-events: none;
    }
  }

    .input-wrapper {
        ${props => props.isCustom && css`
            width: 100%;
      `}
    }
  .label {
    font-size: 11px;
    color: ${props => props.theme.palette.dashboard.textLight};
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .show-date {
    display: flex;
    align-items: center;
    width: 200px;
    height: 40px;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 5px;
      ${props => props.isCustom && css`
        width: 100%;
      `}
    .content {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      padding-left: 10px;
      padding-right: 30px;
      user-select: none;
      cursor: pointer;

      span {
        font-size: 13px;
        color: ${props => props.theme.palette.dashboard.textPrimary};
      }

      svg {
        position: absolute;
        right: 10px;
        color: ${props => props.theme.colors.blue50};
        font-size: 17px;
      }
    }

    ${props => props.theme.breakpoints.down(500)} {
      width: fit-content;
    }
  }
`

const DateRange: FC<IDatePickerProps> = ({ date, setDate, isCustom = false, showActionButtons, label }) => {
  const classes = useStyles()

  const onPrev = () => {
    setDate(addDays(date || new Date(), -1))
  }

  const onNext = () => {
    setDate(addDays(date || new Date(), 1))
  }

  return (
    <Container isCustom={isCustom}>
      {
        showActionButtons &&
        <>
          <div onClick={onPrev} className='button prev'>
            <ArrowBack />
          </div>
          <div onClick={onNext} className={clsx('button next', moment().isSame(date, 'day') && 'disabled')}>
            <ArrowForward />
          </div>
        </>
      }
      <Box className="input-wrapper">
        {!!label && <label className="label">{label}</label>}
        <div className='show-date'>
          <Dropdown
            overlayStyle={{ zIndex: 2000 }}
            overlay={
              <Menu className={classes.dropdown}>
                <Calendar
                  date={date}
                  onChange={date => setDate(date)}
                />
              </Menu>
            }
            trigger={['click']}
          >
            <div className="content">
            <span className="date-text">
              {date && moment(date).format('ddd, MMM DD, YYYY')}
            </span>
              <CalendarIcon fontSize='small'/>
            </div>
          </Dropdown>
        </div>
      </Box>

    </Container>
  )
}

export default DateRange