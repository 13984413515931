import React, { useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "../../../store/selectors";
import { setTeam, setTeams } from "../../../store/actions/team.action";
import { TableContainer } from '../../../components/Dashboard/CommonStyled'
import { Box } from "@material-ui/core";
import { Table } from 'antd'
import UserImg from '../../../assets/images/dashboard/user-avatar.png'
import styled from 'styled-components'
import TableActionMenu from '../../../components/Dashboard/TableActionMenu'
import * as TeamApi from '../../../apis/team.api'

interface DataType {
  key: React.Key
  name: string
  members: string[]
  projects: 3
}

export const MemberList = styled.div`

  img {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 5px;
  }
`

export const Name = styled.div`
  display: flex;
  align-items: center;
  color: #53aaff;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    background-color: #82b55e;
    margin-right: 10px;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`

const TeamTable = ({ searchValue, setOpenModal }) => {
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name) => <Name>
        <div className='avatar'>
          {name[0]}
        </div>
        {name}
      </Name>
    },
    {
      title: 'Managers',
      dataIndex: 'managers',
      render: managers => (
        <MemberList>
          { managers.map((manager, index) => (
            <div key={index} style={{ display: 'flex', marginTop: 5 }}>
              <img src={UserImg} alt='manager' style={{ marginRight: 3 }} />
              <div>{manager.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Members',
      dataIndex: 'members',
      render: members => (
        <MemberList>
          { members.map((member, index) => (
            <div key={index} style={{ display: 'flex', marginTop: 3, marginBottom: 3 }}>
              <img src={UserImg} alt='member' style={{ marginRight: 3 }} />
              <div>{member.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Projects',
      dataIndex: 'projects',
      render: (projects: string[]) => (
        projects.map((name, index) => (
          <Name key={index} style={{ marginTop: 3, marginBottom: 3 }}>
            <div className='avatar'>
              {name[0]}
            </div>
            { name }
          </Name>
        ))
      )
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row) => <TableActionMenu row={row} handleEditRow={handleEditRow} handleDeleteRow={handleDeleteRow}/>
    }
  ];

  const tableData = useMemo(() => {
    return teams.map((team) => (
      {
        ...team,
        key: team.id,
      }
    ))
  }, [teams]);

  const handleEditRow = async (id: string) => {
    await TeamApi.getById(id).then((res) => dispatch(setTeam(res)));
    setOpenModal(true);
  };

  const handleDeleteRow = async (id: string) => {
    await TeamApi.deleteById(id);
    TeamApi.getAll(searchValue).then((res) => dispatch(setTeams(res)));
  };

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
      <Box mt={2}>
        <p>Showing { tableData?.length } of { tableData?.length } { tableData?.length > 1 ? 'teams' : 'team' }</p>
      </Box>
    </TableContainer>
  )
}

export default TeamTable;
