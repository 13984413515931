import React from "react";
import { Box } from "@material-ui/core";
import { ITeamInterface } from "./index";
import { FormikProps } from "formik";
import TextArea from "../../../Common/TextArea";
import * as S from "./style";

const GeneralTab = ({...props}: FormikProps<ITeamInterface>) => {
  return (
    <Box>
      <S.FormControl>
        <span className="label">
          <span className="switch-label">
            Team Name*
          </span>
        </span>
        <TextArea
          name="name"
          placeholder="Add team name"
          value={props.values.name}
          onChange={(e) => props.setFieldValue('name', e.target.value)}
          error={props.errors.name || props.touched.name ? props.errors.name : ""}
        />
      </S.FormControl>
    </Box>
  )
}
export default GeneralTab;
