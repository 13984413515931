import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import DatePicker from "../../DatePicker";
import InputGroup from "../../../Common/InputGroup";
import {FormikProps} from "formik";
import {ITaskInterface} from "./index";

const TaskTime = ({ ...props }: FormikProps<ITaskInterface>) => {
  const [date, setDate] = useState<Date>(new Date(props.values.startDate));

  useEffect(() => {
    props.setFieldValue('startDate', date);
  }, [date]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker label="Start date" isCustom date={date} setDate={setDate}/>
        </Grid>
        <Grid item xs={6}>
          <InputGroup label="Duration" name='duration' addonAfter="h" type='number' { ...props } />
        </Grid>
      </Grid>
    </Box>
  )
}
export default TaskTime;
