import React from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { CssBaseline } from '@material-ui/core'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: white;
  }
`

const Root = styled.div`
  width: 100%;
  height: 100vh;
`

const WelcomeLayout = ({ children }) => {
    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            {children}
        </Root>
    )
}

export default WelcomeLayout
