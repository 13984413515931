import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setProjects } from "../../../../store/actions/project.actions";
import { getMembers, getProjects } from "../../../../store/selectors";
import { setMembers } from "../../../../store/actions/member.action";
import { setTasks } from "../../../../store/actions/task.action";
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { Select as ASelect, Switch } from 'antd'
import { Clear, FilterNone, Launch, Search } from '@material-ui/icons'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import Select from '../../../../components/Dashboard/Select'
import Input from '../../../../components/Dashboard/Input'
import Button from '../../../../components/Dashboard/Button'
import AddTaskModal from "../../../../components/Dashboard/Todo/AddTaskModal";
import TaskTable from "./TaskTable";
import clsx from 'clsx'
import AsanaIcon from '../../../../assets/images/dashboard/asana-icon.png'
import * as S from './styles'
import * as ProjectApi from '../../../../apis/project.api'
import * as TaskApi from '../../../../apis/task.api'
import * as MemberApi from '../../../../apis/member.api'

const { Option } = ASelect

const useStyles = makeStyles(() => ({
  projectSelect: {
    "minWidth": 400,
    '& .ant-select-selector': {
      height: '38px !important'
    }
  },
  assigneeSelect: {
    "minWidth": 150,
    '& .ant-select-selector': {
      height: '38px !important'
    }
  },
  projectOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  projectImg: {
    marginRight: 30,
    marginLeft: 'auto',
    width: 20,
    height: 20
  },
  iconButton: {
    "display": 'flex',
    "alignItems": 'center',
    "justifyContent": 'center',
    '& svg': {
      width: 15,
      height: 15,
      margin: '0 5px'
    }
  },
  switch: {
    "display": 'flex',
    "alignItems": 'center',
    '& .label': {
      fontSize: 12,
      lineHeight: '12px',
      textTransform: 'uppercase',
      color: '#79899d',
      marginLeft: 5
    }
  },
  search: {
    maxWidth: 200,
    borderRadius: '30px !important'
  },
}));

export interface IFilterParam {
  project: string | undefined,
  assignee: string,
  task: string,
  completed: boolean,
}

const Todos = () => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const members = useSelector(getMembers);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean>(true);
  const [filterParam, setFilterParam] = useState<IFilterParam>({
    project: undefined,
    assignee: '',
    task: '',
    completed: false,
  });

  useEffect(() => {
    ProjectApi.getAll('').then((res) => dispatch(setProjects(res)));
    MemberApi.getAll().then((res) => dispatch(setMembers(res)));
  }, []);

  useEffect(() => {
    TaskApi.getAll(filterParam).then((res) => dispatch(setTasks(res)));
  }, [filterParam]);

  const handleClearProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterParam({ ...filterParam, project: undefined });
  };

  const handleClearAssignee = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterParam({ ...filterParam, assignee: '' });
  };

  const handelDuplicateProject = (state: boolean) => {
    setIsDuplicated(!isDuplicated);
    TaskApi.getDuplicatedProjects(state).then((res) => dispatch(setTasks(res)));
  };

  return (
    <S.Container>
      <Box mb={2}>
        <h1>Tasks | <span className='sub-title'><img src={AsanaIcon} alt='Asana' />Asana (cgblockchain.com)</span></h1>
      </Box>
      <Box className='tool-bar' display='flex' justifyContent='space-between'>
        <Box className='left' display='flex'>
          <Box mr={3} position='relative'>
            <Select
              label='Project'
              className={classes.projectSelect}
              placeholder='Selector a project'
              onChange={(e) => setFilterParam({...filterParam, project: e})}
            >
              { projects.length > 0 &&
                projects.map((project) => (
                  <Option key={project.id} value={project.id as string}>
                    <span className={classes.projectOption}>
                      { project.name }
                      <img className={classes.projectImg} src={AsanaIcon} alt='Arbitage - BE' />
                    </span>
                  </Option>
                ))
              }
            </Select>
            {
              filterParam.project && (
                <Clear
                  className="clear-icon"
                  cursor='pointer'
                  style={{
                    position: 'absolute',
                    fontSize: 15,
                    bottom: '11.5px',
                    right: '30px',
                    color: 'rgba(0, 0, 0, 0.25)'
                  }}
                  onClick={(e) => handleClearProject(e)}
                />
              )
            }
          </Box>
          <Box position='relative'>
            <Select
              label='Assignee'
              className={classes.assigneeSelect}
              placeholder='Select assignee'
              onChange={(e) => setFilterParam({...filterParam, assignee: e})}
            >
              { members.length > 0 &&
                members.map((member) => (
                  <Option key={member.id} value={member.id as string}>
                    <span className={classes.projectOption}>
                      { member.name }
                    </span>
                  </Option>
                ))
              }
            </Select>
            {
              filterParam.assignee && (
                <Clear
                  className="clear-icon"
                  cursor='pointer'
                  style={{
                    position: 'absolute',
                    fontSize: 15,
                    bottom: '11.5px',
                    right: '30px',
                    color: 'rgba(0, 0, 0, 0.25)'
                  }}
                  onClick={(e) => handleClearAssignee(e)}
                />
              )
            }
          </Box>
        </Box>
        <Box className='right' display='flex' pt='20px' justifyContent='flex-end'>
          <Box mr={3}>
            <Button
              icon={<FilterNone />}
              className={classes.iconButton}
              onClick={() => handelDuplicateProject(isDuplicated)}
            >
              { isDuplicated ? 'Duplicate project' : 'All project' }
            </Button>
          </Box>
          <Box>
            <Button
              className={clsx(classes.iconButton, 'primary-contained')}
              onClick={() => setOpenModal(true)}
            >
              Add a task <Launch />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className='search-wrapper' pt={3} display='flex' justifyContent='space-between' alignItems='flex-start'>
        <div className={classes.switch}>
          <Switch
            size='small'
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={() => setFilterParam({ ...filterParam, completed: !filterParam.completed })}
          />
          <span className='label'>Show complicated Tasks</span>
        </div>
        <Input
          className={classes.search}
          prefix={<Search />}
          placeholder='Search tasks'
          onChange={(e) => setFilterParam({ ...filterParam, task: e.target.value })}
        />
      </Box>
      <TaskTable
        setOpenModal={setOpenModal}
        filterParam={filterParam}
      />
      <AddTaskModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        filterParam={filterParam}
      />
    </S.Container>
  )
}

export default Todos;
