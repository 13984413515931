import { combineReducers } from 'redux'

import homeReducer from './home.reducer'
import dashboardReducer from './dashboard.reducer'
import projectReducer from "./project.reducer";
import teamReducer from "./team.reducer";
import memberReducer from "./member.reducer";
import taskReducer from "./task.reducer";
import invoiceReducer from "./invoice.reducer";
import clientReducer from "./client.reducer";
import timeSheetReducer from "./timesheet.reducer";

export default combineReducers({
  homeReducer,
  dashboardReducer,
  projectReducer,
  teamReducer,
  memberReducer,
  taskReducer,
  invoiceReducer,
  clientReducer,
  timeSheetReducer
});
