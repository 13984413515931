import styled from "styled-components";

const Container = styled.div`
  min-height: 100vh;

  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;

  .illustration-container {
    flex: 0 0 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 30px 40px 30px;
    background: url(https://server-assets.hubstaff.com/62899462f8/illustrations/customer_retention_illustration-600-cc50f04f2444ff659ec26fca553b5547d05575e87c6060131f9d8e821d391d76.png)
      no-repeat center center;
    background-size: cover;
    max-height: 100vh;

    .steps-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 18px;
        color: white;
        display: block;
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        margin-bottom: 3.3rem;
        padding-left: 0;
      }
    }
  }

  .ant-steps-icon {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check-icon {
    width: 24px;
    height: 24px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.3);

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .onboarding-container {
    width: 100%;
    min-width: 60%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: auto;
  }

  .content {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .hubstaff-logo {
    justify-content: flex-end;
    margin-right: 60px;
    margin-bottom: 15px;

    display: flex;
    margin-top: 30px;
  }

  .text {
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    color: #617083;
  }

  .options {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .default {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .welcome-illustration {
    max-width: 425px;
    width: 100%;
  }

  .alternative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #617083;
    width: 100%;
  }

  .paths {
    width: 100%;
    gap: 10px;
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .primary-btn {
    background-color: #2aa7ff;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid #2aa7ff;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 1.45;
    border-radius: 6px;
    user-select: none;
    max-width: 17.3rem;
    margin-bottom: 4rem;
    color: white;
  }

  .primary-btn:hover,
  .primary-btn:focus,
  .primary-btn:active {
    background-color: #1ba1ff;
    border-color: #1ba1ff;
  }

  .outlined-button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 1.45;
    border-radius: 6px;
    user-select: none;
    color: #3e4956;
    background-color: #fff;
    border: 1px solid #e4e9ef;
    //font-family: Roboto,serif;
  }

  .optional-buttons-row {
    display: flex;
    gap: 8px;
  }

  .outlined-button:hover {
    border-color: #c4cfdc;
  }
  .outlined-button:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .ant-steps-item-tail::after {
    width: 2px !important;
    height: 96% !important;
  }

  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttons {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .ant-steps-item-tail {
    padding: 24px 0 0 !important;
    height: 105% !important;
    top: 4px !important;
  }

  .ant-steps-item.ant-steps-item-active {
    .ant-steps-item-title {
      color: #0275d8 !important;
      font-weight: 500;
      background-color: white;
      border: solid 2px white;
      border-radius: 100px;
      max-width: 100px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 24px;
      position: absolute;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      line-height: 10px;
    }

    .ant-steps-item-tail::after {
      background: white;
    }
  }

  .ant-steps-item.ant-steps-item-finish {
    .ant-steps-item-tail::after {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .ant-steps-icon {
      font-size: 24px !important;
      line-height: 24px !important;
      border-radius: 999px;
    }

    .ant-steps-item-tail::after {
      background-color: rgba(255, 255, 255, 0.3);
    }

    .ant-steps-item-title {
      color: rgba(255, 255, 255, 0.3) !important;
    }
  }
  .ant-steps-item.ant-steps-item-wait {
    .ant-steps-item-tail::after {
      background-color: rgba(255, 255, 255, 0.3);
    }

    .ant-steps-item-title {
      color: rgba(255, 255, 255, 0.3) !important;
    }
  }
  .ant-steps-item {
    height: 120px;
  }

  ${(props) => props.theme.breakpoints.down("xl")} {
    .illustration-container {
      flex: 0 0 400px;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    min-height: unset;

    .illustration-container {
      .title {
        font-size: 17px;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-height: unset;
    flex-direction: row;

    .illustration-container {
      flex: 0 0 200px;
      max-width: 40%;
      max-height: 100vh;
      display: flex;
      .title {
        font-size: 17px;
      }
    }

    .content {
      padding: 0 20px;
    }

    .hubstaff-logo {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .paths {
      flex-direction: row;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    flex-direction: column;
    .illustration-container {
      width: 100vw;
      height: 100px;
      flex: 0 0 200px;
      max-width: 100%;
      max-height: 100px;
      padding: 20px;
      background-size: cover;
      display: flex;
      overflow-y: auto;

      .title {
        display: none;
      }
    }
  }
`;

export { Container };
