import { Steps } from "antd";
import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import { RadioButtonUnchecked } from "@material-ui/icons";
import AdjustIcon from "@material-ui/icons/Adjust";
const Step = Steps.Step;

interface Props {
  step: number;
}

const Stepper = (props: Props) => {
  const { step } = props;
  const getIcons = (value: number) => {
    return step > value ? (
      <div className="check-icon">
        <CheckIcon style={{ color: "white" }} width={6} height={6} color="inherit" />
      </div>
    ) : step < value ? (
      <RadioButtonUnchecked style={{ color: "white" }} />
    ) : (
      <AdjustIcon style={{ color: "white" }} />
    );
  };
  return (
    <Steps direction="vertical" current={step}>
      <Step title="Organization" icon={getIcons(0)} />
      <Step title="Preferences" icon={getIcons(1)} />
      <Step title="Integrations" icon={getIcons(2)} />
      <Step title="Team" icon={getIcons(3)} />
    </Steps>
  );
};

export default Stepper;
