import Http from "./http";
import { IProjectInterface } from "../components/Dashboard/Poject/AddProjectModal";

export const create = (value: IProjectInterface) => {
  return Http.post('/project', value);
};

export const getAll = (searchValue: string) => {
  return Http.get('/project/all', { searchValue });
};

export const getById = (id: string) => {
  return Http.get(`/project/${id}`);
};

export const getMembersByProject = (id: string) => {
  return Http.get(`/project/byProject/${id}`);
}

export const getProjectsByDeveloper = (id: string) => {
  return Http.get(`/project/byDeveloper/${id}`);
}

export const update = ({id, data}: {id: string, data: IProjectInterface}) => {
  return Http.patch(`/project/${id}`, data);
};

export const deleteById = (id: string) => {
  return Http.delete(`/project/${id}`);
};
