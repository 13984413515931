import React from "react";
import { Input as AInput, InputProps } from "antd";
import styled, { css } from "styled-components";
import { XCircle } from "react-feather";
import { Search } from "@material-ui/icons";
interface Props extends InputProps {
  error?: string;
  onClear?: () => void;
}

const SearchWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 0 !important;
  position: relative;
  .search-icon {
    left: 14px;
    position: absolute;
    top: 14px;
    //width: 14px;
    //height: 14px;
    color: #79899d;
  }
  .search-clear {
    right: 14px;
    color: #79899d;
    cursor: pointer;
    position: absolute;
    top: 14px;
    display: inline-block;
    font: normal normal normal 14px / 1 "hubstaff-icons";
    font-size: inherit;
    text-transform: none;
    vertical-align: text-top;
    text-rendering: auto;
    speak: none;
    width: 14px;
    height: 14px;
    line-height: 1;
    &:hover {
      color: #2aa7ff;
    }
  }
`;

const StyledInput = styled(AInput)<{ error?: string }>`
  &.ant-input {
    padding: 0 30px 0 34px;
    border-radius: 20px;
    display: block;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 1.45;
    color: #3e4956;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e4e9ef;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    &:hover:not(:focus) {
      border-color: #c4cfdc;
    }
    &:focus {
      border-color: #2aa7ff;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(42, 167, 255, 0.6);
    }
  }
`;

const SearchInput = (props: Props) => {
  return (
    <SearchWrapper>
      <Search className="search-icon" />
      <StyledInput {...props} />
      <XCircle onClick={props.onClear} className="search-clear" />
    </SearchWrapper>
  );
};

export default SearchInput;
