import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ArrowBack, ArrowForward, Info } from "@material-ui/icons";
import { Tooltip } from "antd";
import { ArrowDown } from "react-feather";
import { DefaultButton, PrimaryButton } from "../../../../components/Common/Button";
import { IOrganization } from "../../../../shared/interfaces";
import ScreenShotImage from "../../../../assets/images/screenshots.svg";
import AppsNUrlsImage from "../../../../assets/images/apps_n_urls.png";
import PersonalComputer from "../../../../assets/images/personal-computer.png";
import CompanyOwnedComputer from "../../../../assets/images/company-owned-computer.png";
import * as S from "./styles";
import * as OrganizationApi from "../../../../apis/organization.api";

const apps_n_urls: Array<{ label: string; value: "show_all" | "blur" | "off"; description: string }> = [
  { label: "Show apps URLs", value: "show_all", description: "View exact apps & URLs for everyone" },
  {
    label: "Blur for individuals",
    value: "blur",
    description: "For greater privacy, blur exact apps & URLs for individuals and teams"
  },
  { label: "Off", value: "off", description: "Do not track apps or URLs for anyone" }
];

const screenshots: Array<{ label: string; value: "standard" | "blurred" | "off"; description: string }> = [
  { label: "Standard", value: "standard", description: "Display detailed screenshots" },
  {
    label: "Blurred",
    value: "blurred",
    description: "For greater privacy, blur screenshots"
  },
  { label: "Off", value: "off", description: "Do not record any screenshots" }
];

interface Props {
  onNext: (value: IOrganization) => void;
  onBack: () => void;
}

const OrganizationWizardPreferences = (props: Props) => {
  const { onNext, onBack } = props;
  const { id } = useParams();
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [selectedAppsAndUrls, setSelectedAppsAndUrls] = useState<"show_all" | "blur" | "off">("show_all");
  const [selectedScreenShot, setSelectedScreenShot] = useState<"standard" | "blurred" | "off">("standard");

  useEffect(() => {
    OrganizationApi.getById(id).then((res) => {
      if (res.devices) {
        setSelectedDevices(JSON.parse(res.devices));
        setSelectedAppsAndUrls(res.appsAndUrls);
        setSelectedScreenShot(res.screenShot);
      }
    });
  }, []);

  const handleSelectDevice = (value: string) => {
    if (selectedDevices.length === 2) return;
    setSelectedDevices([value]);
  };

  const handleUseBothDevice = (value: boolean) => {
    if (value) setSelectedDevices(["personal_device", "company_device"]);
    else setSelectedDevices([""]);
  };
  return (
    <S.Container>
      <h1 className="title">How will you use TimeGo</h1>
      <div className="sub-title">Device Usage*</div>
      <S.Options>
        <input type="hidden" name="organization[preferred_device]" value="" />
        <div
          className={`device ${selectedDevices.includes("personal_device") && "active"}`}
          onClick={() => handleSelectDevice("personal_device")}
        >
          <label className="device-content" htmlFor="organization_preferred_device_personal">
            <div className="icon">
              <img alt="personal computer" src={PersonalComputer} />
            </div>
            <input
              className="radio-option"
              value="personal"
              type="radio"
              name="organization[preferred_device]"
              id="organization_preferred_device_personal"
            />
            <div className="title">Personal computers</div>
            <div className="description">
              Your team can start and stop the timer as needed. They have visibility into the data collection on their
              device.
            </div>
          </label>
        </div>
        <div
          className={`device ${selectedDevices.includes("company_device") && "active"}`}
          onClick={() => handleSelectDevice("company_device")}
        >
          <label className="device-content" htmlFor="organization_preferred_device_company_owned">
            <div className="icon">
              <img alt="personal computer" src={CompanyOwnedComputer} />
            </div>
            <input
              className="radio-option"
              value="company_owned"
              type="radio"
              name="organization[preferred_device]"
              id="organization_preferred_device_company_owned"
            />
            <div className="title">Company owned</div>
            <div className="description">
              You can choose to install the Hubstaff Corporate app which starts and stops automatically, and collects
              data without direct employee interaction.
            </div>
          </label>
        </div>
      </S.Options>
      <div className="both-checkbox">
        <label style={{ display: "flex", alignItems: "center" }} htmlFor="organization_preferred_device">
          <input
            type="checkbox"
            name="organization[preferred_device]"
            id="organization_preferred_device"
            value="all"
            onChange={(e) => handleUseBothDevice(e.target.checked)}
          />
          We will use both
        </label>
      </div>

      <div className="sub-title">
        Member data privacy
        <Tooltip
          color="#515966"
          overlay={
            "Control the level of privacy for members who use the Desktop or Corporate app to track time and activity"
          }
        >
          <Info width={12} height={12} style={{ color: "#7a8798", width: 16, height: 16 }} />
        </Tooltip>
      </div>
      <div className="note">Note: You can modify these settings later</div>

      <S.PrivacySettings>
        <div className="apps-and-urls">
          <div className="toggle-label">APPS &amp; URLs</div>
          <div className="apps-and-urls-toggle toggle-multiple-options" data-toggle="buttons">
            {apps_n_urls.map((item) => (
              <div
                onClick={() => setSelectedAppsAndUrls(item.value)}
                className={`btn btn-toggle-option ${selectedAppsAndUrls === item.value && "active"}`}
                id="app-n-urls-show"
                key={item.value}
              >
                <input
                  type="radio"
                  value={item.value}
                  name="organization[app_and_url_preference]"
                  id={`organization_app_and_url_preference_${item.value}`}
                />
                {item.label}
                <div className="description-arrow">
                  <ArrowDown />
                </div>
              </div>
            ))}
          </div>
          <div className="descriptions">
            {apps_n_urls.map((item) => (
              <div
                key={item.value}
                className="description"
                id="app-n-urls-show-description"
                style={{ display: selectedAppsAndUrls === item.value ? "flex" : "none" }}
              >
                <img alt="apps_n_urls" src={AppsNUrlsImage} />
                {item.description}
              </div>
            ))}
          </div>
        </div>
        <div className="screenshots">
          <div className="toggle-label">SCREENSHOTS</div>
          <div className="toggle-multiple-options screenshots-toggle" data-toggle="buttons">
            {screenshots.map((item) => (
              <div
                onClick={() => setSelectedScreenShot(item.value)}
                className={`btn btn-toggle-option ${selectedScreenShot === item.value && "active"}`}
                id="screenshot-show"
                key={item.value}
              >
                <input
                  type="radio"
                  value={item.value}
                  name="organization[screenshot_preference]"
                  id={`organization_screenshot_preference_${item.value}`}
                />
                {item.label}
                <div className="description-arrow">
                  <ArrowDown />
                </div>
              </div>
            ))}
          </div>
          <div className="descriptions">
            {screenshots.map((item) => (
              <div
                key={item.value}
                className="description"
                id="screenshot-description"
                style={{ display: selectedScreenShot === item.value ? "flex" : "none" }}
              >
                <img alt="screenshots" src={ScreenShotImage} />
                {item.description}
              </div>
            ))}
          </div>
        </div>
      </S.PrivacySettings>
      <div className="buttons">
        <DefaultButton onClick={onBack}>
          <ArrowBack />
          Back
        </DefaultButton>
        <PrimaryButton
          onClick={() => onNext({
            devices: JSON.stringify(selectedDevices),
            appsAndUrls: selectedAppsAndUrls,
            screenShot: selectedScreenShot
          })}
        >
          Next
          <ArrowForward />
        </PrimaryButton>
      </div>
    </S.Container>
  );
};

export default OrganizationWizardPreferences;
