import * as types from "../actionTypes";

const initialValue: {
  clients: any,
} = {
  clients: [],
}

const clientReducer = (state = initialValue, action: any) => {
  switch(action.type) {
    case types.SET_CLIENTS: {
      return {
        ...state,
        clients: action.payload,
      }
    }
    default:
      return state;
  }
}
export default clientReducer;
