import styled from 'styled-components'

const Container = styled.div`
  min-height: 100vh;

  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;

  .illustration-container {
    flex: 0 0 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 150px 30px 40px 30px;
    background: url(https://server-assets.hubstaff.com/62899462f8/illustrations/customer_retention_illustration-600-cc50f04f2444ff659ec26fca553b5547d05575e87c6060131f9d8e821d391d76.png) no-repeat center center;
    background-size: cover;
  }

  .onboarding-container {
    width: 100%;
    min-width: 60%;
    display: flex;
    flex-direction: column;
  }

  .content {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .hubstaff-logo {
    justify-content: flex-end;
    margin-right: 60px;
    margin-bottom: 15px;

    display: flex;
    margin-top: 30px;
  }

  .form-wrapper {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 100px 0;
    overflow-y: auto;
  }

  .form {
    width: 100%;
    max-width: 600px;
    margin: 0 20px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0;
    color: #222E50;
    font-family: 'Ubuntu', sans-serif;
    font-size: 34px;
  }

  .text {
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    color: #617083;
  }

  .options {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .default {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .welcome-illustration {
    max-width: 425px;
    width: 100%;
  }

  .alternative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #617083;
    width: 100%;
  }

  .paths {
    width: 100%;
    gap: 10px;
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .primary-btn {
    background-color: #2aa7ff;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid #2aa7ff;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 1.45;
    border-radius: 6px;
    user-select: none;
    max-width: 17.3rem;
    margin-bottom: 4rem;
    color: white;
  }

  .primary-btn:hover, .primary-btn:focus, .primary-btn:active{
    background-color: #1ba1ff;
    border-color: #1ba1ff;
  }

  .outlined-button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 1.45;
    border-radius: 6px;
    user-select: none;
    color: #3e4956;
    background-color: #fff;
    border: 1px solid #e4e9ef;
    font-family: Roboto,serif;
  }

  .outlined-button:hover {
    border-color: #c4cfdc;
  }
  .outlined-button:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    min-height: unset;

    .illustration-container {
      flex: 0 0 200px;
      max-width: 40%;
      max-height: 100vh;
      display: flex;
    }

    .form {
      max-width: 600px;
      margin: 0 80px;
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    min-height: unset;
    flex-direction: column;

    .illustration-container {
      display: none;
    }

    .content {
      padding: 0 20px;
    }

    .hubstaff-logo {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .form-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      overflow-y: auto;
    }

    .form {
      max-width: 600px;
      margin: 0 20px;
    }

    .title {
      font-size: 24px;
    }

    .paths {
      flex-direction: row;
    }

  }
`

export { Container }
