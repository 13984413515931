import Stepper from "../Stepper";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Logo } from "../../Common/Icons";
import * as S from "./style";
import React from "react";

interface Props {
  children: JSX.Element;
  step: number;
}

const WizardLayout = (props: Props) => {
  const { children, step } = props;
  return (
    <S.Container>
      <div className="illustration-container no-steps">
        <div className="steps-wrapper">
          <div className="title">Organization set up</div>
          <Stepper step={step} />
        </div>
      </div>
      <div className="onboarding-container">
        <div className="content welcome">
          <div className="hubstaff-logo">
            <a
              title="Hubstaff online time tracking"
              className="mb-5 ml-auto welcome-top-logo logo-new-onboarding"
              target="_blank"
              href="/"
            >
              <Box height={50} className="mb-5 ml-auto welcome-top-logo logo-new-onboarding">
                <Link to={"/"}>
                  <Logo height={50} />
                </Link>
              </Box>
            </a>
          </div>
          <div className="form-container">{children}</div>
        </div>
      </div>
    </S.Container>
  );
};

export default WizardLayout;
