import React from "react";
import { Box } from "@material-ui/core";
import * as S from "./style";
import TextArea from "../../../Common/TextArea";
const GeneralTab = ({ ...props }) => {
  return (
    <Box>
      <S.FormControl>
        <span className="label">
          <span className="switch-label">
            Task NAME*
          </span>
        </span>
        <TextArea
          name="name"
          placeholder="Add task names"
          value={props.values.name}
          onChange={(e) => props.setFieldValue('name', e.target.value)}
          error={props.errors || props.touched ? props.errors.name : ""}
        />
      </S.FormControl>
    </Box>
  )
}
export default GeneralTab;
