import styled from "styled-components";
import { Popover } from '@material-ui/core'

const SelectorWrapper = styled.div`
  position: relative;
    margin-bottom: 15px;
  .control-label {
      font-weight: 500;
      color: #79899d;
      text-transform: uppercase;
      font-size: 11px;
      display: inline-flex;
      max-width: 100%;
      margin-bottom: 5px;
  }

  .label-secondary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 7px;
      color: #79899d;;
      font-size: 13px;
  }
`;

const PopoverContainer = styled(Popover)`
    .MuiPopover-paper {
        border-radius: 8px !important;
    }
`;
const SelectSelection = styled.div`
    .app-selector {
        display: block;
        width: 100%;
        color: #999;
        cursor: pointer;
        box-shadow: none;
        padding: 10px 14px;
        line-height: 18px;
        text-align: left;
        border-radius: 6px !important;
        background-color: #fff;
        border: 1px solid #e4e9ef;
    }

    .app-select {
        .select-container {
            display: block;
            box-sizing: border-box;
            position: relative;
            vertical-align: middle;
        }

        .clear-all-trigger {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            font-size: 9px;
            appearance: none;
            border: none;
            background: none;
            padding: 10px 15px !important;
            align-items: center !important;
            justify-content: center !important;
            display: flex !important;
            color: #777777;
            cursor: pointer;
            svg {
                width: 14px;
                height: 14px;
            }
        }
        .selection-counter {
            align-items: center !important;
            display: flex !important;
            border-radius: 6px;
            border: 1px solid #e4e9ef;
            padding: 8px 5px 8px 10px;
            font-size: 14px;
            border-left: 4px solid #7d68ff;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            min-height: 42px;
        }
    }
`;

const EmptyBox = styled.div`
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;

    img {
        width: 108px;
        height: 76px;
    }
`;

const PopoverContent = styled.div`
  display: flex;
  padding: 0;
  //position: absolute;
  border: none;
  //border-radius: 8px;
  background-color: white;
  overflow: hidden;
  //box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 8020;
  width: 540px;
  flex-direction: column;

  .lazy-select-content-header {
    padding-top: 15px !important;
    flex-direction: column;
    display: flex !important;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 16px;
    gap: 15px;
    border-bottom: 1px solid #e4e9ef;
  }

  .lazy-select-content-results {
    .lazy-select-content-results-list {
      padding-bottom: 30px;
      height: 100%;
      max-height: 340px;
      overflow-x: hidden;
      overflow-y: auto;

      .custom-checkbox-wrapper {
        padding: 10px 15px;
        font-weight: 400;
        display: flex;
        align-items: center;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        cursor: pointer;
        color: #2aa7ff;
      }
    }

    .lazy-select-content-results-list-item {
      display: flex;
      align-items: center;
        &.disabled {
            opacity: 0.6;
        }
        &.selected {
            background: rgba(42, 167, 255, 0.05);
        }
        &.hidden {
            display: none;
        }

      &:hover {
        background: rgba(42, 167, 255, 0.05);
      }

      .custom-checkbox-wrapper {
        font-weight: 400;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        cursor: pointer;
        max-width: 100%;

        input {
          display: none;
          margin: 4px 0 0;
          box-sizing: border-box;
          padding: 0;
        }

        .custom-checkbox-box {
          margin-right: 8px;
          display: inline-block;
          text-align: left;
          width: 16px;
          height: 16px;
          border: 1px solid rgba(0, 0, 0, 0.085);
          vertical-align: middle;
          transition: background-color 0.1s ease;
          border-radius: 4px;
          opacity: 0.85;
          cursor: pointer;
        }

        .custom-checkbox-box:before {
          transition: width 0.05s linear;
          transition-delay: 0.1s;
          transform: rotate(45deg) translateX(5px) translateY(3px);
          content: "";
          position: absolute;
          transform-origin: 0 100%;
          width: 0;
          border-bottom-color: #fff;
          border-bottom-width: 2px;
          border-bottom-style: solid;
          text-align: left;
        }

        .custom-checkbox-box:after {
          transition: width 0.1s linear;
          transition-delay: 0s;
          transform: rotate(-45deg) translateX(-2px) translateY(11px);
          content: "";
          position: absolute;
          transform-origin: 0 100%;
          width: 0;
          border-bottom-color: #fff;
          border-bottom-width: 2px;
          border-bottom-style: solid;
        }

        input:checked + .custom-checkbox-box {
            background-color: #2aa7ff;
        }
        input:checked + .custom-checkbox-box {
            position: relative;
            transition-delay: 0s;
        }

        input:checked + .custom-checkbox-box:before {
            width: 6px;
            transition-delay: 0s;
        }
          input:checked + .custom-checkbox-box:after {
              width: 10px;
              transition-delay: 0.05s;
          }

        .check-box-label {
          display: flex;
          align-items: center;
          margin: 0;
          width: 100%;
          gap: 10px;
          white-space: normal;

          .lazy-select-content-results-list-item-content {
            padding-right: 10px;
            align-items: center !important;
            display: flex !important;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
          }

          .avatar-speck {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 5px;
            vertical-align: middle;
          }

          .lazy-select-content-results-list-item-content-name {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .lazy-select-content-footer {
    padding: 10px 15px !important;
    align-items: center !important;
    display: flex !important;
    position: sticky;
    left: 0;
    bottom: 0;
    gap: 16px;
    background: white;
    border-top: 1px solid #e4e9ef;
  }

  .toggle-switch-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    color: #777777;
    font-weight: 500;

    input {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
      margin: 0;
    }

    input[type="checkbox"]:disabled ~ .toggle-switch, .toggle-switch-wrapper input[type="radio"]:disabled ~ .toggle-switch {
        opacity: 0.4;
        cursor: not-allowed;
    }

    .toggle-switch {
      height: 16px;
      width: 32px;
      min-width: 32px;
      position: relative;
      margin: 0;
      background-color: #d3d5d7;
      transition: 0.15s background-color;
      will-change: background-color;
      border-radius: 20px;
      cursor: pointer;
    }

    .toggle-switch:after {
      content: "\\f11d";
      color: #b5b9bd;
      font-size: 6px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 222;
      vertical-align: middle;
      background: #fff;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.13);
      margin: 2px;
      transform: translateX(0);
      transition: 0.15s transform;
      will-change: transform;
    }
    input[type="checkbox"]:disabled ~ .toggle-switch-label {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .toggle-switch-label {
      padding-left: 10px;
      font-size: 12px;
      line-height: 1;
      text-transform: none;
      cursor: pointer;
      font-weight: 500;
    }

    input[type="checkbox"]:checked ~ .toggle-switch {
        background-color: #1f95ff;
        text-align: left;
    }

    input[type="checkbox"]:checked ~ .toggle-switch:after {
        content: "\\f119";
        font-size: 8px;
        color: #1f95ff;
        transform: translateX(16px);
    }
  }
`;

export { SelectorWrapper, PopoverContent, PopoverContainer, SelectSelection, EmptyBox };
