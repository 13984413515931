import React from 'react';
import classNames from "classnames";
import Select, { CSSObjectWithLabel, SingleValue } from "react-select";
import { StylesConfig } from "react-select/dist/declarations/src/styles";
import { SelectComponentsConfig } from "react-select/dist/declarations/src/components";
import { GroupBase } from "react-select/dist/declarations/src/types";
import { ReactElement } from "react";
import * as S from './style';
import { Box } from "@material-ui/core";
import { Clear } from "@material-ui/icons";

export interface SelectOptionsProp {
  value: any;
  label: string | ReactElement;
  [key: string]: any;
}

// TODO: add types for control

export interface SelectProps {
  options: SelectOptionsProp[];
  defaultValue?: SelectOptionsProp | SelectOptionsProp[];
  components?: SelectComponentsConfig<SelectOptionsProp, false, GroupBase<SelectOptionsProp>>;
  value?: SelectOptionsProp | null;
  classNamePrefix?: string;
  name: string;
  className?: string;
  label?: string;
  control?: any;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  styles?: StylesConfig<SelectOptionsProp, false>;
  onChange?: (newValue: SingleValue<SelectOptionsProp>) => void;
  noOptionsMessage?: () => string;
  onBlur?: () => void;
  onClear?: () => void;
}

const CustomSelect = (props: SelectProps) => {
  const className = classNames(props.className, "select");
  const componentProps = {
    ...props,
    classNamePrefix: "select",
    className,
    isSearchable: props.isSearchable ?? false
  };
  const onChangeHandler = (newValue: SingleValue<SelectOptionsProp>, controlChange: any) => {
    controlChange(newValue?.value);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  return(
    <S.SelectWrapper>
      {!!props.label && <label className="control-label">{props.label}</label>}
      <Box position="relative">
        <Select
          {...componentProps}
          styles={{
            option: (styles: CSSObjectWithLabel, { isDisabled }) => ({
              ...styles,
              "whiteSpace": "nowrap",
              "color": isDisabled ? "#ccc !important" : "currentColor",
              "cursor": isDisabled ? "not-allowed" : "default",
              ":hover": {
                background: isDisabled ? "transparent !important" : "#DEEBFF"
              }
            }),
            ...componentProps.styles
          }}
        />
        {props.value && <Clear className="clear-icon" onClick={props.onClear} />}
      </Box>
    </S.SelectWrapper>
  )
};

export const SelectFilter = (props: SelectProps) => {
  const className = classNames(props.className);
  const componentProps = {
    ...props,
    styles: {
      control: (styles: CSSObjectWithLabel) => ({ ...styles, minHeight: 27 }),
      valueContainer: (styles: CSSObjectWithLabel) => ({ ...styles, height: 27 }),
      placeholder: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: 14, lineHeight: "19px" }),
      input: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: 14, lineHeight: "19px" }),
      singleValue: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: 14, lineHeight: "19px" }),
      menu: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: 14, lineHeight: "19px" }),
      indicatorSeparator: (styles: CSSObjectWithLabel) => ({ ...styles, marginTop: 0, marginBottom: 0 }),
      ...props.styles
    },
    classNamePrefix: "select",
    className,
    isSearchable: props.isSearchable ?? false
  };
  return (
    <Select
      {...componentProps}
    />
  );
};

export default CustomSelect
