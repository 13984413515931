import React from "react";
import { FormikProps } from "formik";
import { ITimeSheetInterface } from "./index";
import * as S from "./style";
import TextArea from "../../../Common/TextArea";

const NoteTab = ({ ...props }: FormikProps<ITimeSheetInterface>) => {
  return (
    <>
      <S.FormControl>
        <span className="label">
          <span className="switch-label">
            Note
          </span>
        </span>
        <TextArea
          name='note'
          placeholder="Add notes"
          value={props.values.note}
          onChange={(e) => props.setFieldValue('note', e.target.value)}
        />
      </S.FormControl>
    </>
  )
}
export default NoteTab;
