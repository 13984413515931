import Http from './http'
import { IOrganization } from "../shared/interfaces";

export const create = ({ userId, values }: { userId: string | null, values: IOrganization }) => {
  return Http.post(`/organization/${userId}`, values);
}

export const getById = (id: string) => {
  return Http.get(`/organization/${id}`);
}

export const update = ({ id, value }: { id: string, value: IOrganization }) => {
  return Http.patch(`/organization/${id}`, value);
}
