import { ITeamInterface } from "../../components/Dashboard/Team/AddTeamModal";
import * as types from "../actionTypes";

export const setTeams = (value: ITeamInterface[]) => {
  return {
    type: types.SET_TEAMS,
    payload: value,
  }
}

export const setTeam = (value: ITeamInterface | null) => {
  return {
    type: types.SET_TEAM,
    payload: value
  }
}
