import styled from "styled-components";
import { AppPage } from "../../../components/Dashboard/CommonStyled";

// @ts-ignore
const Container = styled(AppPage)`
  .search-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${(props) => props.theme.breakpoints.down("xs")} {
      span {
        max-width: 100%;
      }
    }
  }

  .search {
    max-width: 400px;
    width: 400px;
    border-radius: 30px !important;
  }

  .tooltip {
    padding: 12px;
    width: 120px;
    border-radius: 8px;
  }

  .actions-row {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

export { Container };
