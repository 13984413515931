import styled from "styled-components";

const Container = styled.div`
  .title {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0;
    color: #222e50;
    //font-family: 'Ubuntu', sans-serif;
    font-size: 34px;
  }

  .sub-title {
    font-size: 18px;
    font-weight: 400;
    text-transform: initial;
    color: #2d3137;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .both-checkbox {
    justify-content: flex-end;
    width: 100%;
    display: flex;
    label {
      font-size: 14px;
      margin-bottom: 0;
      color: #7a8798;
      input {
        margin-right: 8px;
        width: 14px;
        height: 14px;
        border-radius: 3px;
        border: 1px;
      }
    }
  }

  .note {
    color: #617083;
    font-size: 13px;
    margin-bottom: 10px;
  }
`;

const Options = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 14px;
  justify-content: space-between;
  width: 100%;

  .device {
    margin-bottom: 10px;
    flex: 1 0 270px;

    label.device-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 35px;
      max-height: 180px;
      width: 100%;
      height: 100%;
      border: 0.1px solid #d6dbe6;
      border-radius: 7px;
      margin-bottom: 0;
      cursor: pointer;
      position: relative;
      flex: 1 1 400px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      text-transform: initial;
      color: #2d3137;
    }
  }

  .device.active {
    label {
      border-color: #2aa7ff;
      box-shadow: 0 0 3.7px 0.9px #2aa7ff80;
    }
  }

  .icon {
    position: absolute;
    bottom: 85%;
    left: 43.34%;
    img {
      max-width: 52px;
      min-width: 52px;
      max-height: 52px;
      min-height: 52px;
    }
  }

  input[type="radio"] {
    display: none;
  }

  .title {
    font-family: "Ubuntu", sans-serif;
    font-size: 16px;
    margin-bottom: 16px;
  }

  .description {
    font-size: 14px;
  }
`;

const PrivacySettings = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 70px;
  gap: 30px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }

  .apps-and-urls,
  .screenshots {
    display: flex;
    flex-direction: column;
    max-width: 470px;
  }

  .toggle-label {
    font-size: 11px;
    font-weight: 500;
    color: #617083;
    margin-bottom: 14px;
    text-transform: uppercase;
  }

  .toggle-multiple-options {
    display: flex;
    min-width: 370px;
    max-width: 370px;
    align-items: center;
    background-color: #e9edef;
    border-radius: 20px;
    input[type="radio"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  .btn {
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 1.45;
    border-radius: 6px;
    user-select: none;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    //background-color: #e9edef;
    font-weight: normal;
    height: 32px;
    position: relative;
    min-width: 100px;
  }

  .btn-toggle-option {
    width: auto;

    .description-arrow {
      display: none;
    }
  }

  .btn-toggle-option.active {
    height: 36px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    border-radius: 20px;
    margin-top: -2px;
    z-index: 333;
    width: auto;

    .description-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: #d6dbe6 solid 1px;
      position: absolute;
      top: 150%;
      background-color: white;
      cursor: default;

      svg {
        stroke-width: 1px;
      }
    }
  }

  .select {
    display: none;
  }

  .description {
    align-items: center;
    margin-top: 30px;
    max-height: 70px;
    min-height: 70px;
    padding: 0 20px;
    border-radius: 8px;
    border: 1px solid #d6dbe6;
    background-color: #f4f6f9;
    img {
      margin-right: 10px;
    }
  }
`;

export { Container, Options, PrivacySettings };
