import styled from "styled-components";

const FormContainer = styled.div`
  width: 100%;
  max-width: 822px;
  padding: 0 20px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 80px;

  .title {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0;
    color: #222e50;
    //font-family: 'Ubuntu', sans-serif;
    font-size: 34px;
  }

  .options {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .alternative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #617083;
    width: 100%;
  }

  .optional-buttons-row {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .checkbox {
    label {
      font-size: 14px;
      color: #7a8798;
      display: flex;
      align-items: center;
      input {
        margin-right: 5px;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down("xl")} {
    max-width: 782px;
    margin: 0 80px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    max-width: 600px;
    margin: 0 80px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 0 80px;

    .form-wrapper {
      max-width: 600px;
      margin: 0 20px;
    }

    .title {
      font-size: 24px;
    }

    .paths {
      flex-direction: row;
    }
  }
`;

const FormControl = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;

  .label {
    //font-family: Ubuntu,serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: initial;
    color: #2d3137;
    margin-bottom: 5px;
  }

  > div {
    width: 100%;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    flex-direction: column;

    .label {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
`;

export { FormContainer, FormControl };
