import styled from "styled-components";
import { Box } from '@material-ui/core'

const SelectWrapper = styled(Box)`
    .control-label {
        font-weight: 500;
        color: #79899d;
        text-transform: uppercase;
        font-size: 11px;
        display: inline-flex;
    }
    .select__control {
        height: 40px !important;
    }
    .clear-icon {
        width: 12px;
        height: 12px;
        color: #9D9D9D;
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        &:hover {
            color: #27303c;
        }
    }

    .select__indicators {
        color: #3e4956 !important;
    }
    .select__indicator {
        padding-right: 10px !important;
        padding-left: 10px !important;

        svg {
            // current
            width: 14px !important;
            height: 14px !important;
        }
    }
        cursor: pointer;
        width: 100%;
        font-size: 14px;
        color: #3e4956;

        &:hover {
            //current
            .select__control,
            .select__control ~ .select__menu {
                border-color: #BFC0C1FF !important;
            }
            .select__control--menu-is-open {
                border-bottom-color: transparent !important;
            }
        }

        .select__control {
            // reset

            height: 33px;
            min-height: unset;
            //background-color: transparent;
            //border: none;
            transition: none;
            box-shadow: none;
            background: white;

            // current

            cursor: pointer;
            border: 1px solid #BFC0C1FF;
            border-radius: 6px;

            &--menu-is-open {
                //&:hover {
                //    border: 1px solid #2aa7ff;
                //}
                //border: 1px solid #2aa7ff !important;
                border-bottom-color: transparent !important;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .select__value-container {
            // reset

            display: flex;
            padding: 0;

            // current

            padding: 5px 9px 3px;
            flex: 1 1;
            flex-wrap: nowrap;
        }

        .select__placeholder {
            //reset

            margin-right: 0;
            margin-left: 0;

            // current

            color: #9D9D9D;
        }

        .select__single-value {
            // reset

            margin: 0;
            color: currentColor;
        }
        [class*="DummyInput"] {
            // reset

            position: absolute;
        }

        .select__indicators {
            // reset

            flex-shrink: 0;
            color: #5FB883;
        }
        .select__indicator {
            // reset

            color: currentColor;
            padding: 0;

            // current

            padding-right: 5px;
            padding-left: 5px;

            svg {
                // current
                width: 20px;
                height: 20px;
            }
        }

        .select__indicator-separator {
            // reset

            display: none;
        }
        .select__control.select__control--menu-is-open ~.select__menu {
            //border: 1px solid #2aa7ff;
            border-top: none;
        }
        .select__menu {
            //reset

            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.15);
            border-radius: 0;
            background-color: transparent;
            margin: 0;
            overflow: hidden;
            // current
            margin-bottom: 15px;
            background-color: white;
            border: 1px solid #BFC0C1;
            border-top: none;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
        .select__menu-list {
            // reset

            padding: 0;

            // current

            // padding-top: 8px;
        }
        .select__option {
            // reset

            cursor: pointer;
            overflow: hidden;
            // current

            padding: 9px 9px;

            &--is-focused,
            &--is-selected {
                // reset
                height: 40px;
                background-color: transparent;
                color: currentColor;

                // current

            }

            &--is-focused:hover {
                background-color: #F0F4F7;
            }

            &--is-selected {
                background-color: #2AA7FF!important;
                color: white;
            }
        }
    .select__menu-notice--no-options {
        text-align: left;
        color: #2b343f;
    }

`
export { SelectWrapper };
