import styled from "styled-components";

const Container = styled.div`
  .title {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0;
    color: #222e50;
    //font-family: 'Ubuntu', sans-serif;
    font-size: 34px;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    margin-bottom: 20px;
    color: #617083;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 400;
    text-transform: initial;
    color: #2d3137;
    max-width: 100%;
    margin-bottom: 5px;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  margin-left: 12px;
`;

const AddMemberButton = styled.div`
  cursor: pointer;
  font-weight: 600;
  color: #2aa7ff;
  margin-bottom: 24px;
`;

export { Container, AddMemberButton, CloseButton };
