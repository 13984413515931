import moment from "moment";

export const timeStyle = (value: number) => {
  const time = moment.duration(value, "seconds");

  return {
    hour: (time.hours() + time.days() * 24).toString(),
    minute: time.minutes().toString().padStart(2, '0'),
    second: time.seconds().toString().padStart(2, '0'),
  }
}
