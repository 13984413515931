import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {setTimeSheet, setTimeSheets} from "../../../../store/actions/timesheet.action";
import { getTimeSheet } from "../../../../store/selectors";
import { Form, Formik, FormikProps } from "formik";
import { Tabs } from "../../CommonStyled";
import { IFilterInterface } from "../../../../pages/Dashboard/TimeSheets/ViewAndEdit";
import PersonProjectTab from "./PersonProjectTab";
import DateDurationTab from "./DateDurationTab";
import NoteTab from "./NoteTab";
import Button from "../../Button";
import Modal from "../../Modal";
import * as Yup from "yup";
import * as S from "./style";
import * as TimeSheetApi from "../../../../apis/timesheet.api";
import moment from "moment";

const { TabPane } = Tabs;

export interface ITimeSheetInterface {
  developer: {
    value: string,
    label: string
  } | null;
  project: {
    value: string,
    label: string
  } | null;
  startDate: Date;
  endDate: Date;
  duration: number;
  note: string;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  filterParams: IFilterInterface;
  setWorkTime: (value: number) => void;
  secondReading:　number;
}

const TimeSheetModal = ({
  open,
  onClose,
  filterParams,
  setWorkTime,
  secondReading
}: IProps) => {
  const dispatch = useDispatch();
  const timesheet = useSelector(getTimeSheet);

  useEffect(() => {
    if (!open) {
      dispatch(setTimeSheet(null));
    }
  }, [open]);

  const timeSheetSchema = Yup.object().shape({
    developer: Yup.object().required(),
    project: Yup.object().required(),
    startDate: Yup.date().required(),
    endDate: Yup.date(),
    duration: Yup.number(),
    note: Yup.string(),
  });

  const initialValues = useMemo(() => {
    return {
      developer: timesheet?.developer || null,
      project: timesheet?.project || null,
      startDate: timesheet?.startDate || new Date(),
      endDate: (
        timesheet?.progressing
          ? moment(timesheet?.endDate).add(secondReading, 'second')
          : timesheet?.endDate
      ) || new Date(),
      duration: (
        timesheet?.progressing
          ? timesheet?.duration + secondReading
          : timesheet?.duration
      ) || 0,
      note: timesheet?.note || ''
    }
  }, [timesheet, secondReading]);

  const handleSubmit = async (values: ITimeSheetInterface) => {
    if (timesheet) {
      if (!timesheet.progressing) {
        await TimeSheetApi.update(
          {
            id: timesheet.id,
            data: {
              ...values,
              developer: values.developer?.value,
              project: values.project?.value
            }
          }
        );
      } else {
        await TimeSheetApi.update(
          {
            id: timesheet.id,
            data: {
              developer: values.developer?.value,
              project: values.project?.value,
              note: values.note
            }
          }
        );
      }
    } else {
      await TimeSheetApi.create({
        ...values,
        developer: values.developer?.value,
        project: values.project?.value,
        progressing: false,
      });
    }
    await TimeSheetApi.getTimeSheets(filterParams).then((res) => {
      dispatch(setTimeSheets(res.data));
      setWorkTime(res.time);
    });
    onClose();
  };

  return (
    <Modal onClose={onClose} open={open}>
      <S.ModalContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={timeSheetSchema}
        >
          {(props: FormikProps<ITimeSheetInterface>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className='tab-content'>
                <Tabs defaultActiveKey="1">
                  <TabPane tab='PERSON & PROJECT' key="1">
                    <PersonProjectTab { ...props } />
                  </TabPane>
                  <TabPane tab='DATE & DURATION' key="2">
                    <DateDurationTab { ...props } />
                  </TabPane>
                  <TabPane tab='NOTE' key="3">
                    <NoteTab {...props} />
                  </TabPane>
                </Tabs>
              </div>
              <div className="modal-footer">
                <Button className="gray-outlined" onClick={onClose}>Cancel</Button>
                <Button
                  htmlType='submit'
                  className="primary-contained"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </S.ModalContainer>
    </Modal>
  )
}

export default TimeSheetModal;
