import React, { useEffect, useState } from "react";
import CostInput from "../../../Common/CostInput";
import InputGroup from "../../../Common/InputGroup";
import DatePicker from "../../DatePicker";
import CustomSelect from "../../../Common/Select/Select";
import { Box, Grid } from "@material-ui/core";
import { FormikProps } from "formik";
import { IProjectInterface } from "./index";
import _ from "lodash";

const types = [
  {value: 'total_coast', label: 'Total coast' },
  {value: 'total_hours', label: 'Total hours' }
]

const basedOn = [
  {value: 'bill_rate', label: 'Bill rate' },
  {value: 'pay_rate', label: 'Pay rate' }
]

const resets = [
  {value: 'never', label: 'Never' },
  {value: 'monthly', label: 'Monthly' }
]

const BudgetAndLimitTab = ({ ...props }: FormikProps<IProjectInterface>) => {
  const [date, setDate] = useState<Date>(new Date(props.values.startDate));

  useEffect(() => {
    props.setFieldValue('startDate', date);
  }, [date]);

  return (
    <Box p={0.25} pb={8} display="flex" flexDirection="column" gridGap={15}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomSelect
            label="TYPE*"
            name="budgetType"
            placeholder="Select a type"
            options={types}
            styles={{
              control: (style) => ({ ...style, width: "100%" })
            }}
            value={
              props.values.budgetType
                ? { value: props.values.budgetType, label: _.upperFirst(props.values.budgetType).replace('_', ' ') }
                : null
            }
            onChange={(e) => props.setFieldValue('budgetType', e?.value)}
            onClear={() => props.setFieldValue('budgetType', null)}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomSelect
            label="BASED ON*"
            name="basedOn"
            placeholder="Select a rate"
            options={basedOn}
            styles={{
              control: (style) => ({ ...style, width: "100%" })
            }}
            value={
              props.values.basedOn
                ? { value: props.values.basedOn, label: _.upperFirst(props.values.basedOn).replace('_', ' ') }
                : null
            }
            onChange={(e) => props.setFieldValue('basedOn', e?.value)}
            onClear={() => props.setFieldValue('basedOn', null)}
          />
        </Grid>
        <Grid item xs={4}>
          <CostInput label="COST*" { ...props }/>
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={6}>
          <InputGroup label="Notify at" name='notifyAt' addonAfter="% of budget" { ...props } />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomSelect
            label="RESETS*"
            name="reset"
            placeholder="Select a rate"
            options={resets}
            styles={{
              control: (style) => ({ ...style, width: "100%" })
            }}
            value={props.values.reset ? { value: props.values.reset, label: _.upperFirst(props.values.reset) } : null}
            onChange={(e) => props.setFieldValue('reset', e?.value)}
            onClear={() => props.setFieldValue('reset', null)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker label="Start date" isCustom date={date} setDate={setDate}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BudgetAndLimitTab;
