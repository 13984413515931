import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {　getTasks　} from "../../../../store/selectors/task.selector";
import { setTask, setTasks } from "../../../../store/actions/task.action";
import {　TableContainer　} from "../../../../components/Dashboard/CommonStyled";
import {　Box　} from "@material-ui/core";
import {　MemberList　} from "../../Team/TeamTable";
import {　Table　} from "antd";
import {　IProjectInterface} from "../../../../components/Dashboard/Poject/AddProjectModal";
import { IMemberInterface } from "../../../../components/Dashboard/Team/AddTeamModal";
import UserImg from "../../../../assets/images/dashboard/user-avatar.png";
import moment from "moment-timezone";
import TableActionMenu from "../../../../components/Dashboard/TableActionMenu";
import styled from 'styled-components';
import * as TaskApi from '../../../../apis/task.api';

const TeamList = styled.div`
  display: flex;

  .team {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #549a58;
    color: white;
    margin-right: 10px;
  }
`

const Name = styled.div`
  display: flex;
  align-items: center;
  color: #53aaff;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    background-color: #82b55e;
    margin-right: 10px;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`

const TaskTable = ({ setOpenModal, filterParam }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(getTasks);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, row) => <Name>
        <div className='avatar'>
          {name[0]}
        </div>
        {name}
        { row.icon &&
            <img src={row.icon} alt={name} />
        }
      </Name>
    },
    {
      title: 'Developers',
      dataIndex: 'developer',
      render: (developer: IMemberInterface[]) => (
        <MemberList>
          { developer.map((member, index) => (
            <div key={index} style={{ display: 'flex', marginTop: 3, marginBottom: 3 }}>
              <img src={UserImg} alt='member' style={{ marginRight: 3 }} />
              <div>{member.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Project',
      dataIndex: 'project',
      render: (project: IProjectInterface) => (
        <Name style={{ marginTop: 3, marginBottom: 3 }}>
          <div className='avatar'>
            {project.name[0]}
          </div>
          { project.name }
        </Name>
      )
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (startDate) => {
        return (
          <div>{moment(new Date(startDate)).format('DD/MM/YYYY')}</div>
        )
      }
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (duration: number) => {
        return (
          <div>{duration}h</div>
        )
      }
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row) => <TableActionMenu row={row} handleDeleteRow={handleDeleteRow} handleEditRow={handleEditRow} />
    }
  ];

  const tableData = useMemo(() => {
    return tasks.map((task) => ({
      ...task,
      key: task.id
    }))
  }, [tasks]);

  const handleDeleteRow = async (id: string) => {
    await TaskApi.deleteById(id);
    TaskApi.getAll(filterParam).then((res) => dispatch(setTasks(res)));
  }

  const handleEditRow = async (id: string) => {
    await TaskApi.getById(id).then((res) => dispatch(setTask(res)));
    setOpenModal(true);
  }

  return (
    <TableContainer>
      <div style={{ marginTop: '3rem' }}>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Box mt={2}>
          <p>Showing { tableData.length } of { tableData.length } { tableData.length > 1 ? 'tasks' : 'task' }</p>
        </Box>
      </div>
    </TableContainer>
  )
}
export default TaskTable;
