import styled, { keyframes } from "styled-components";
import { Box } from "@material-ui/core";
import { Button as AButton } from "antd";

const Container = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  background-color: ${(props) => props.theme.colors.dark};
  min-height: 557px;
  justify-content: center;

  .left-panel {
    flex: 1;
    position: relative;
  }

  .right-panel {
    flex: 1;
    position: relative;
  }

  .main-content {
    text-align: center;
    padding: 150px 0 200px;

    h1 {
      font-size: 45px;
      color: white;
      margin-bottom: 10px;
    }

    p {
      color: white;
      margin-bottom: 25px;
    }

    ${(props) => props.theme.breakpoints.down("xs")} {
      h1 {
        font-size: 35px;
      }
    }
  }

  &::after {
    position: absolute;
    top: calc(100% - 32px);
    left: calc(50% - 250vw);
    width: 500vw;
    height: 300vh;
    background: ${(props) => props.theme.colors.blue100};
    border-radius: 50%;
    content: "";
    display: block;
    z-index: 0;
  }

  ${(props) => props.theme.breakpoints.down(1250)} {
    flex-direction: column;

    .main-content {
      padding: 20px 15px;
    }

    .right-panel,
    .left-panel {
      min-height: 400px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    .right-panel,
    .left-panel {
      min-height: 350px;
    }
  }

  ${(props) => props.theme.breakpoints.down(500)} {
    button {
      width: 100% !important;
    }
  }
`;

const Button = styled(AButton)`
  width: 250px !important;
  background-color: ${(props) => props.theme.colors.blue} !important;
  color: ${(props) => props.theme.colors.white} !important;
  height: 60px !important;
  padding: 10px 20px !important;
  border-radius: 7px !important;
  transition: all ease 0.3s;
  border: none !important;

  span {
    font: 16px "Proxima Nova Bold";
    font-weight: 600;
    line-height: 1.5;
  }

  &:hover {
    opacity: 0.8;
  }

  span {
    font-size: 18px;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    transform: scale(0.2);
    opacity: 1;
  }
  30% {
    transform: scale(0.4);
  }
  45% {
    transform: scale(0.6);
  }
  60% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const Icon = styled(Box)<{ background?: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.background || "white"};
  border-radius: 50%;
  overflow: hidden;
  animation: ${pulse} 0.8s ease-out;

  &.size-large {
    width: 125px;
    height: 125px;
  }

  &.size-medium {
    width: 100px;
    height: 100px;
  }

  &.size-small {
    width: 65px;
    height: 65px;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    svg {
      transform: scale(0.7);
    }

    &.size-large {
      width: 90px;
      height: 90px;
    }

    &.size-medium {
      width: 70px;
      height: 70px;
    }

    &.size-small {
      width: 50px;
      height: 50px;
    }
  }
`;

export { Container, Button, Icon };
